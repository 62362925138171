import Keycloak from "keycloak-js";

// Setup Keycloak instance as needed
// Pass initialization options as required or leave blank to load from 'keycloak.json'
const keycloak = new Keycloak({
  url: process.env.REACT_APP_KEYCLOAK_AUTH_URL,
  realm: "timefactory",
  clientId: "timefactory",
});

export default keycloak;
