import {forwardRef, Fragment, useImperativeHandle, useState} from "react";
import {Dialog, Transition} from "@headlessui/react";
import {PlayerElement} from "../../shared/PlayerElement/PlayerElement";
import {CustomButton} from "../../shared/CustomButton/CustomButton";
import { useTranslation } from "react-i18next";

export interface IDailyPlayerModal {
  setModalOpen: (modalOpen: boolean) => void;
}

type DailyPlayerModalProps = {
  url: string;
};

export const DailyPlayerModal = forwardRef((props: DailyPlayerModalProps, ref) => {
  const [open, setOpen] = useState(false);
  const{t} = useTranslation();

  // forward ref to open the modal from stack
  useImperativeHandle(ref, () => ({
    setModalOpen(modalOpen: boolean) {
      setOpen(modalOpen);
    },
  }));

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog as="div" className="relative z-10" onClose={() => setOpen(false)}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 overflow-y-auto">
          <div className="flex min-h-full items-center justify-center p-4 text-center ">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 "
              enterTo="opacity-100 translate-y-0 "
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 "
              leaveTo="opacity-0 translate-y-4 "
            >
              <Dialog.Panel className="group relative w-[42rem] transform overflow-hidden rounded-lg bg-panterra-800 p-5 text-left shadow-xl transition-all">
                <div className="flex items-center justify-between pb-3 pt-5">
                  <Dialog.Title className="mx-1 text-2xl text-white">{t('timeLapseGeneratorPreviewHeader')}</Dialog.Title>
                  <CustomButton
                    action={() => setOpen(false)}
                    iconProps={{
                      icon: "XMarkIcon",
                    }}
                  />
                </div>
                <PlayerElement videoURL={props.url} />
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
});
