import {PropsWithChildren, useContext} from "react";
import {Navigate, useLocation} from "react-router-dom";
import {ProjectsContext} from "../../../context/ProjectsProvider";
import {UserConfigurationContext} from "../../../context/UserConfigurationProvider";

export const ProtectedRoute = (props: PropsWithChildren) => {
  const {state: projects} = useContext(ProjectsContext);
  const {isAdmin} = useContext(UserConfigurationContext);
  const location = useLocation();

  const isAuthorized = (): boolean => {
    if (isAdmin) return true;

    const groupRegex = new RegExp(/projects\/(\w{5})/);
    const match = location.pathname.match(groupRegex);

    return !!(match?.[1] && Object.keys(projects).includes(match[1]));
  };

  if (!isAuthorized()) {
    return <Navigate to="/" replace={true} />;
  }

  return <>{props.children}</>;
};
