import {ReactKeycloakProvider} from "@react-keycloak/web";
import {PropsWithChildren} from "react";
import {Pageloader} from "../components/shared/Pageloader/Pageloader";
import keycloak from "../services/keycloak";
import {UserConfigurationProvider} from "./UserConfigurationProvider";
import { useTranslation } from "react-i18next";

export const UserProvider = (props: PropsWithChildren<any>) => {
  const {t} = useTranslation();
  const loadingComponent = (
    <div className={"flex h-full min-h-full flex-col overflow-auto bg-panterra-900 text-panterra-50"}>
      <div className={"mx-auto min-h-full bg-panterra-900"}>
        <div className={"py-10 text-xl"}>{t('waitingforKeycloak')}</div>
        <Pageloader />
      </div>
    </div>
  );

  const initOptions = {
    onLoad: "login-required"
  };

  return (
    <ReactKeycloakProvider
      authClient={keycloak}
      initOptions={initOptions}
      LoadingComponent={loadingComponent}
    >
      <UserConfigurationProvider>{props.children}</UserConfigurationProvider>
    </ReactKeycloakProvider>
  );
};
