import * as HeroIcons from "@heroicons/react/24/solid";
import classNames from "classnames";
import {ReactComponent as FullScreenSVG} from "../../../assets/icons/expand-solid.svg";
import {ReactComponent as ExitFullScreenSVG} from "../../../assets/icons/compress-solid.svg";
import {ReactComponent as HourGlassSVG} from "../../../assets/icons/hourglass-solid.svg";
import {ReactComponent as GearSVG} from "../../../assets/icons/gear-solid.svg";

//Todo: Only allow valid icons
export type PanterraIcon = string;

export type IconProps = {
  icon: PanterraIcon;
  iconClassName?: string;
};

export const Icon = (props: IconProps) => {
  const {...icons} = HeroIcons;
  const customIcons = {
    FullScreenSVG: FullScreenSVG,
    ExitFullScreenSVG: ExitFullScreenSVG,
    HourGlassSVG: HourGlassSVG,
    GearSVG: GearSVG,
  };

  // @ts-ignore
  let DynamicIcon: JSX.Element = icons[props.icon] ? icons[props.icon] : customIcons[props.icon];

  const iconClassName = classNames("text-white", {
    [`${props.iconClassName}`]: props.iconClassName,
    "h-7 w-7": !props.iconClassName,
  });

  return (
    <>
      {/* @ts-ignore */}
      <DynamicIcon className={iconClassName} aria-hidden="true" />
    </>
  );
};
