import {LatestImage} from "../projects/LatestImages/LatestImage";
import {Camera, CustomWidget, Image, Project} from "../../types/types";
import { CustomWidgetTile } from "../projects/CustomWidgetTile/CustomWidgetTile";
import { ImageWidget } from "../projects/ImageWidget/ImageWidget";
import { useTranslation } from "react-i18next";

type ProjectProps = {
  project: Project;
};

export const ProjectTiles = (props: ProjectProps) => {

  const cameras = props.project.cameras;
  const customWidgets = props.project.customWidgets;
  const images = props.project.imageWidgets;
  const {t} = useTranslation();

  return (
    <>
      { !Object.values(cameras).length &&
        <div className={"text-center pb-4"}>
          {t('noActiveCamerasYetInProject')}
        </div>
      }
      <div className={"grid grid-cols-1 md:grid-cols-2 gap-8"}>
          {Object.values(cameras)
            .sort((a: Camera, b: Camera) => parseInt(a.cameraNumber) - parseInt(b.cameraNumber))
            .map((camera: Camera) => (
              <LatestImage project={props.project} camera={camera} key={camera.id} />
            ))}
          {Object.values(customWidgets).map((customWidget: CustomWidget) =>(
            <CustomWidgetTile customWidget={customWidget} project={props.project} key={`iframe-${customWidget.id}`}></CustomWidgetTile>
          ))}
          {Object.values(images).map((image: Image) =>(
            <ImageWidget image={image} project={props.project} key={`image-${image.id}`}></ImageWidget>
          ))}
      </div>
    </>
  );
};
