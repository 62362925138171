import {createContext, Dispatch, PropsWithChildren, SetStateAction, useState} from "react";

export type LayoutContextType = {
  homeTabIndex: number;
  setHomeTabIndex: Dispatch<SetStateAction<number>>;
};

export const LayoutContext = createContext<LayoutContextType>({
  homeTabIndex: 0,
  setHomeTabIndex: (value) => {},
});

export const LayoutProvider = (props: PropsWithChildren<unknown>) => {
  const [homeTabIndex, setHomeTabIndex] = useState<number>(0);
  // const [dashboardLayouts, setDashboardLayouts] = useState(); ...

  return (
    <LayoutContext.Provider
      value={{
        homeTabIndex,
        setHomeTabIndex,
      }}
    >
      {props.children}
    </LayoutContext.Provider>
  );
};
