import React, {useState} from "react";
import {downloadHelper} from "../../../services/downloadHelper";
import {MediaType} from "../../../types/types";
import {CustomButton} from "../../shared/CustomButton/CustomButton";
import {CustomToolbar} from "../../shared/CustomToolbar/CustomToolbar";
import { useTranslation } from "react-i18next";

type LatestImageToolbarProps = {
  type: MediaType;
  setType: (e: MediaType) => void;
  mediaStackUrl: string;
  imageZoomUrl: string;
  hasDaily: boolean;
  mediaDownloadUrl: string;
  mediaDownloadName: string;
};

export const LatestImageToolbar = (props: LatestImageToolbarProps) => {
  const [downloadRunning, setDownloadRunning] = useState<boolean>(false);
  const {t} = useTranslation();
  const left: JSX.Element[] = [
    <CustomButton
      key={"1"}
      action={() => props.setType("image")}
      iconProps={{
        icon: "PhotoIcon",
      }}
      buttonSelected={props.type === "image"}
      tippyContent={t('tooltip:toggleImageMode')}
    />,
    <CustomButton
      key={"2"}
      action={props.hasDaily ? () => props.setType("daily") : () => {}}
      iconProps={{
        icon: "VideoCameraIcon",
      }}
      buttonSelected={props.type === "daily"}
      tippyContent={t('tooltip:toggleVideoMode')}
    />,
  ];

  const right: JSX.Element[] = [
    <CustomButton
      key={"4"}
      link={props.mediaStackUrl}
      iconProps={{
        icon: "RectangleStackIcon",
      }}
      tippyContent={t('tooltip:openStack')}
    />,
    props.type === "image" ? (
      <CustomButton
        key={"3"}
        link={props.imageZoomUrl}
        iconProps={{
          icon: "ArrowsPointingOutIcon",
        }}
        tippyContent={t('tooltip:openZoom')}
      />
    ) : (
      <React.Fragment key={"3"}></React.Fragment>
    ),
    <CustomButton
      key={"6"}
      action={() => downloadHelper(props.mediaDownloadUrl, props.mediaDownloadName, setDownloadRunning)}
      iconProps={{
        icon: downloadRunning ? "HourGlassSVG" : "ArrowDownTrayIcon",
        iconClassName: downloadRunning ? "w-5 h-5" : undefined,
      }}
      download={props.mediaDownloadName}
      buttonPaddingClassName={downloadRunning ? "mx-1 p-3" : undefined}
      buttonDisabled={downloadRunning}
      tippyContent={props.type === "image" ? t('tooltip:downloadImage') : t('tooltip:downloadVideo')}
    />,
  ];

  return <CustomToolbar left={left} right={right}></CustomToolbar>;
};
