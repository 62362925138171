import ptLogo from "../../../assets/images/pt_branding.svg";

type Props = {
  className?: string;
};

export const ProjectBrandingPTLogo = (props: Props) => {
  return (
    <div className={`absolute bottom-5 left-5 z-20 h-12 ${props.className ?? ""}`}>
      <img src={ptLogo} alt="" className={"h-full"} />
    </div>
  );
};
