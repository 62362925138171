import {Menu, Transition} from "@headlessui/react";
import {Fragment} from "react";
import Tippy from "@tippyjs/react";
import {useTranslation} from 'react-i18next';
import de from "date-fns/locale/de";
import en from "date-fns/locale/en-GB"
import { Locale } from "date-fns";

import { Icon } from "../shared/Icon/Icon";

export const lngs: {
  [key: string]: {
    nativeName: string,
    iconText: string,
    calendarLocale: Locale,
  }} = {
  en: {
    nativeName: 'English',
    iconText: 'EN',
    calendarLocale: en
  },
  de: {
    nativeName: 'Deutsch',
    iconText: 'DE',
    calendarLocale: de
  },
}

export const LanguageMenu = () => {
  const {t, i18n} = useTranslation();
  return (
    <Menu as="div" className={"relative"}>
      <Tippy content={t('tooltip:openLanguageMenu')} delay={[1000, 200]}>
        <Menu.Button className="mx-1 rounded-md p-2 hover:bg-gray-600">
          {i18n.resolvedLanguage &&
            <div className="h-7 w-7" >
              {lngs[i18n.resolvedLanguage].iconText}
            </div>
          }
        </Menu.Button>
      </Tippy>
      <Transition
        as={Fragment}
        enter="transition ease-out duration-100"
        enterFrom="transform opacity-0 scale-95"
        enterTo="transform opacity-100 scale-100"
        leave="transition ease-in duration-75"
        leaveFrom="transform opacity-100 scale-100"
        leaveTo="transform opacity-0 scale-95"
      >
        <Menu.Items
          className={"right-0 absolute z-20 mt-2 origin-top-right rounded-md bg-panterra-700 p-1 text-white"}
        >
          <Menu.Item
          as="div">
              {Object.keys(lngs).map((lng) => (
                <button
                  key={lng}
                  onClick={() => {
                    i18n.changeLanguage(lng)
                  }}
                  className="rounded-md w-full"
                >
                  <div
                    className="flex justify-between items-center px-2 w-full rounded hover:bg-panterra-600"
                  >
                    <span>{lngs[lng].nativeName}</span>
                    {i18n.resolvedLanguage === lng && <Icon iconClassName="ml-2 h-5 w-5" icon="CheckIcon" />}
                  </div>
                </button>
              ))}

          </Menu.Item>

        </Menu.Items>
      </Transition>
    </Menu>
  );
};
