import {createContext, Dispatch, PropsWithChildren, SetStateAction, useState} from "react";

export type CalendarDateTimeContextType = {
  startDate: Date | null;
  setStartDate: Dispatch<SetStateAction<Date | null>>;
  endDate: Date | null;
  setEndDate: Dispatch<SetStateAction<Date | null>>;
  startTime: number;
  setStartTime: Dispatch<SetStateAction<number>>;
  endTime: number;
  setEndTime: Dispatch<SetStateAction<number>>;
};

export const CalendarDateTimeContext = createContext<CalendarDateTimeContextType>({
  startDate: new Date(),
  setStartDate: (value) => {},
  endDate: new Date(),
  setEndDate: (value) => {},
  startTime: 0,
  setStartTime: (value) => {},
  endTime: 24,
  setEndTime: (value) => {},
});

export const CalendarDateTimeProvider = (props: PropsWithChildren<unknown>) => {
  const [startDate, setStartDate] = useState<Date | null>(null);
  const [endDate, setEndDate] = useState<Date | null>(null);
  const [startTime, setStartTime] = useState<number>(0);
  const [endTime, setEndTime] = useState<number>(24);

  return (
    <CalendarDateTimeContext.Provider
      value={{startDate, setStartDate, endDate, setEndDate, startTime, setStartTime, endTime, setEndTime}}
    >
      {props.children}
    </CalendarDateTimeContext.Provider>
  );
};
