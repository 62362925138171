import {CustomButton} from "../../shared/CustomButton/CustomButton";
import { useTranslation } from "react-i18next";

type ControlBarProps = {
  isFullScreen: boolean;
  goHome: () => void;
  zoomIn: () => void;
  zoomOut: () => void;
  toggleFullScreen: () => void;
  children?: JSX.Element[];
};

export const ControlBar = (props: ControlBarProps) => {
  const {t} = useTranslation()
  return (
    <div className="absolute top-0 left-0 z-20 flex flex-row">
      <CustomButton
        action={props.goHome}
        buttonPaddingClassName="p-1 m-1"
        buttonBG="bg-panterra-800"
        iconProps={{
          icon: "HomeIcon",
        }}
        tippyContent={t('tooltip:zoomHome')}
      ></CustomButton>
      <CustomButton
        action={props.zoomIn}
        buttonPaddingClassName="p-1 m-1"
        buttonBG="bg-panterra-800"
        iconProps={{
          icon: "PlusIcon",
        }}
        tippyContent={t('tooltip:zoomIn')}
      ></CustomButton>
      <CustomButton
        action={props.zoomOut}
        buttonPaddingClassName="p-1 m-1"
        buttonBG="bg-panterra-800"
        iconProps={{
          icon: "MinusIcon",
        }}
        tippyContent={t('tooltip:zoomOut')}
      ></CustomButton>
      <CustomButton
        action={props.toggleFullScreen}
        buttonPaddingClassName="p-1 m-1"
        buttonBG="bg-panterra-800"
        iconProps={{
          icon: props.isFullScreen ? "ExitFullScreenSVG" : "FullScreenSVG",
        }}
        tippyContent={t('tooltip:toggleFullscreen')}
      ></CustomButton>
      {props.children && {...props.children}}
    </div>
  );
};
