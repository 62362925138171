import {ConciseRecording, Daily} from "../types/types";

// This method searches for closest timestamp in log(n) and replaces Array.find for our purposes
// It only ises Date.getTime() and is time zone agnostic in that way. Unfortunately in our DB timestamps
// are saved as Europe/Berlin without the Timezone specifier. This might generate faulty dates if
// The goal is not coming from a DB timestamp aswell, but e.g. from a calendar.
export const getNearestTimestampIndex = (stack: ConciseRecording[] | Daily[], goal: string): number => {
  if (!stack || !stack.length) return -1;

  const goalTS = new Date(goal).getTime();
  let leftBorder = 0;
  let rightBorder = stack.length - 1;
  // shrink array until we are left with two candidates, one to the left, one to the right.
  while (rightBorder - leftBorder >= 2) {
    const trial = Math.floor((leftBorder + rightBorder) / 2);
    if (new Date(stack[trial].timestamp).getTime() > goalTS) {
      leftBorder = trial;
    } else {
      rightBorder = trial;
    }
  }
  // Find the closer date. Automatically finds exact matches.
  return new Date(stack[leftBorder].timestamp).getTime() + new Date(stack[rightBorder].timestamp).getTime() > 2 * goalTS
    ? rightBorder
    : leftBorder;
};

export const getNearestTimestamp = (stack: ConciseRecording[] | Daily[], goal: string): string => {
  const index = getNearestTimestampIndex(stack, goal);
  return stack[index].timestamp;
};
