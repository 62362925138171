import {useState} from "react";
import {downloadHelper} from "../../../services/downloadHelper";
import {CustomButton} from "../../shared/CustomButton/CustomButton";
import {CustomToolbar} from "../../shared/CustomToolbar/CustomToolbar";
import { useTranslation } from "react-i18next";

type ImageWidgetToolbarProps = {
  imageZoomUrl: string;
  mediaDownloadUrl: string;
  mediaDownloadName: string;
};

export const ImageWidgetToolbar = (props: ImageWidgetToolbarProps) => {
  const [downloadRunning, setDownloadRunning] = useState<boolean>(false);
  const {t} = useTranslation();

  const right: JSX.Element[] = [
    <CustomButton
      key={"1"}
      link={props.imageZoomUrl}
      iconProps={{
        icon: "ArrowsPointingOutIcon",
      }}
      tippyContent={t('tooltip:openZoom')}
    />,
    <CustomButton
      key={"6"}
      action={() => downloadHelper(props.mediaDownloadUrl, props.mediaDownloadName, setDownloadRunning)}
      iconProps={{
        icon: downloadRunning ? "HourGlassSVG" : "ArrowDownTrayIcon",
        iconClassName: downloadRunning ? "w-5 h-5" : undefined,
      }}
      download={props.mediaDownloadName}
      buttonPaddingClassName={downloadRunning ? "mx-1 p-3" : undefined}
      buttonDisabled={downloadRunning}
      tippyContent={t('tooltip:downloadImage')}
    />,
  ];

  return <CustomToolbar left={[]} right={right}></CustomToolbar>;
};
