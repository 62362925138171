import i18next from 'i18next'
import {initReactI18next} from 'react-i18next'
import LanguageDetector from 'i18next-browser-languagedetector'
import Backend from "i18next-http-backend";

i18next
  .use(initReactI18next)
  .use(LanguageDetector)
  .use(Backend)
  .init({
    // Language fallback is !NOT based on the supportedLngs attribute, as one would expect.
    // Language fallback checks the locales-folder and if it finds non-empty files in the desired locale, sets it.
    // Otherwise it falls back. Regional fallback (de-AT -> de) comes before the default fallback, which we set to "en".
    fallbackLng: "en",
    ns: ['common', 'glossary', 'tooltip', 'admin'],
    defaultNS: 'common',
    nsSeparator: ':',
    backend: {
      loadPath: '/locales/{{lng}}/{{ns}}.json',
    },
    debug: process.env.NODE_ENV === 'development',
  });
