import { useTranslation } from "react-i18next";

export const OSDLegend = () => {
  const {t} = useTranslation();
  return (
    <table className="whitespace-nowrap text-left">
      <tbody>
        <tr>
          <th>{t('tooltip:osdLegendKey')}</th>
          <th className="pl-3">{t('tooltip:osdLegendAction')}</th>
        </tr>
        <tr>
          <td>w / ArrowUp</td>
          <td className="pl-3">{t('tooltip:osdLegendScrollUp')}</td>
        </tr>
        <tr>
          <td>a / ArrowLeft</td>
          <td className="pl-3">{t('tooltip:osdLegendScrollLeft')}</td>
        </tr>
        <tr>
          <td>s / ArrowDown</td>
          <td className="pl-3">{t('tooltip:osdLegendScrollDown')}</td>
        </tr>
        <tr>
          <td>d / ArrowRight</td>
          <td className="pl-3">{t('tooltip:osdLegendScrollRight')}</td>
        </tr>
        <tr>
          <td>+</td>
          <td className="pl-3">{t('tooltip:zoomIn')}</td>
        </tr>
        <tr>
          <td>-</td>
          <td className="pl-3">{t('tooltip:zoomOut')}</td>
        </tr>
        <tr>
          <td>0</td>
          <td className="pl-3 ">{t('tooltip:zoomHome')}</td>
        </tr>
        <tr>
          <td>r</td>
          <td className="pl-3 ">{t('tooltip:osdLegendRotateImage')}</td>
        </tr>
        <tr>
          <td>f</td>
          <td className="pl-3 ">{t('tooltip:osdLegendMirrorImage')}</td>
        </tr>
      </tbody>
    </table>
  );
};
