import apiFetch from "../dataAccess";
import {ProjectState} from "../../context/ProjectsProvider";
import {Camera, CustomWidget, Image, ProjectLogo} from "../../types/types";

export type ApiProject = {
  id: number;
  project: string;
  name: string;
  active: boolean;
  public: boolean;
  description?: string;
  startDate: string;
  contactName?: string;
  contactInformation?: string;
  maxSize?: number;
  allowImageSrcDownload: boolean;
  showPTLogoInTF: boolean;
  showClientLogoInTF: boolean;
  cameras: Camera[];
  imageWidgets: Image[];
  customWidgets: CustomWidget[];
  projectLogo: ProjectLogo;
};

export const getProjectData = async (): Promise<ProjectState> => {
  const response = await apiFetch("projects");
  const projects: ApiProject[] = await response.json();

  return Object.fromEntries(
    projects.map((apiProject) => {
      const activeCameras = apiProject.cameras
        .filter((camera) => camera.active)
        .map((camera) => [camera.cameraNumber, camera]);
      const cameras = Object.fromEntries(activeCameras);
      const customWidgets = Object.fromEntries(apiProject.customWidgets.map(customWidget => [customWidget.id, customWidget]));
      const imageWidgets = Object.fromEntries(apiProject.imageWidgets.map(imageWidget => [imageWidget.id, imageWidget]));
      const project = {...apiProject, cameras, customWidgets, imageWidgets};

      return [project.project, project];
    }),
  );
};
