import {createContext, Dispatch, PropsWithChildren, Reducer, useReducer} from "react";
import {Project} from "../types/types";

export type ProjectState = {
  [key: string]: Project;
};

export type ProjectSetData = {
  type: "SET_DATA";
  payload: ProjectState;
};

export type ProjectContextType = {
  state: ProjectState;
  dispatch: ProjectDispatcher;
};

type ProjectAction = ProjectSetData;
type ProjectReducer = Reducer<ProjectState, ProjectAction>;
type ProjectDispatcher = Dispatch<ProjectAction>;

const reducer: ProjectReducer = (state: ProjectState, action: ProjectAction): ProjectState => {
  switch (action.type) {
    case "SET_DATA":
      return action.payload;
    default:
      return state;
  }
};

export const ProjectsContext = createContext<ProjectContextType>({
  state: {},
  dispatch: (_value) => {},
} as ProjectContextType);

export const ProjectsProvider = (props: PropsWithChildren<unknown>) => {
  const [state, dispatch] = useReducer<ProjectReducer>(reducer, {});
  return <ProjectsContext.Provider value={{state, dispatch}}>{props.children}</ProjectsContext.Provider>;
};
