import {Menu, Transition} from "@headlessui/react";
import {UserIcon} from "@heroicons/react/24/solid";
import {Fragment, useContext} from "react";
import {UserConfigurationContext} from "../../context/UserConfigurationProvider";
import Tippy from "@tippyjs/react";
import { useTranslation } from "react-i18next";

import keycloak from "../../services/keycloak";

export const UserMenu = () => {
  const {t} = useTranslation()
  const {isPtAdmin, emailAddress, userName} = useContext(UserConfigurationContext);
  const handleLogout = async (e: React.MouseEvent) => {
    e.preventDefault();
    keycloak.logout();
  };

  return (
    <Menu as="div" className={"relative"}>
      <Tippy content={t('openUserMenu')} delay={[1000, 200]}>
        <Menu.Button className="mx-1 rounded-md p-2 hover:bg-gray-600">
          <UserIcon className="h-7 w-7" />
        </Menu.Button>
      </Tippy>
      <Transition
        as={Fragment}
        enter="transition ease-out duration-100"
        enterFrom="transform opacity-0 scale-95"
        enterTo="transform opacity-100 scale-100"
        leave="transition ease-in duration-75"
        leaveFrom="transform opacity-100 scale-100"
        leaveTo="transform opacity-0 scale-95"
      >
        <Menu.Items
          className={"absolute right-0 z-20 mt-2 min-w-[180px] origin-top-right rounded-md bg-panterra-700 py-1"}
        >
          <Menu.Item>
            <div className="block w-full whitespace-nowrap p-4 pt-2 text-base text-panterra-800">
              {isPtAdmin && (
                <div className="mx-auto mb-4 mt-2 text-center font-bold text-red-500">
                  {t('hasAdministratorPowers')}
                </div>
              )}
              {!(userName === "") && <div className="my-2 px-1 text-purple-200">{t("loggedInAs", {userName})}</div>}
              {!(emailAddress === "") && <div className="my-2 px-1 text-purple-200">{`Email: ${emailAddress}`}</div>}
              <button
                className={"mt-4 block w-full rounded-md bg-white bg-panterra-100 hover:bg-panterra-200"}
                onClick={(e) => handleLogout(e)}
              >
                {t('logOut')}
              </button>
            </div>
          </Menu.Item>
        </Menu.Items>
      </Transition>
    </Menu>
  );
};
