/* eslint-disable react-hooks/exhaustive-deps */
import {forwardRef, useEffect, useImperativeHandle, useRef} from "react";
import OpenSeaDragon, {Point, TileSourceOptions, Viewer} from "openseadragon";

type Props = {
  tileSource: TileSourceOptions;
  showNavigator: boolean;
  handleNav?: (zoomLevel: number, coordinates: CenterPoint, id: number) => void;
  id: number;
  opacity?: number;
};

export type CenterPoint = {
  x: number;
  y: number;
};

export interface ITileViewer {
  setOpacity: (opacity: number) => void;
  zoomIn: () => void;
  zoomOut: () => void;
  goHome: () => void;
  navigateTo: (zoomLevel: number, coordinates: CenterPoint) => void;
  useNewTileSource: (tileSource: TileSourceOptions, done?: () => void) => void;
}

export const TileViewer = forwardRef<ITileViewer, Props>((props: Props, ref) => {
  let viewer: Viewer | null = null;
  const wrapper = useRef<HTMLDivElement>(null);
  let master: boolean = false;

  useImperativeHandle(ref, () => ({
    setOpacity(opacity: number) {
      if (wrapper.current) wrapper.current.style.opacity = opacity.toString();
    },
    goHome() {
      viewer?.viewport.goHome();
    },
    zoomIn() {
      viewer?.viewport.zoomBy(1.2);
      viewer?.viewport.applyConstraints();
    },
    zoomOut() {
      viewer?.viewport.zoomBy(0.8);
      viewer?.viewport.applyConstraints();
    },
    navigateTo(zoomLevel: number, coordinates: CenterPoint) {
      if (master) {
        return;
      }
      viewer?.viewport.zoomTo(zoomLevel);
      viewer?.viewport.panTo(coordinates as Point);
    },
    useNewTileSource(tileSource: TileSourceOptions, done?: () => void) {
      viewer?.addTiledImage({
        tileSource: tileSource,
        index: 0,
        replace: true,
        success: () => {
          if (done) done();
         }
      });
    },
  }));

  useEffect(() => {
    viewer = OpenSeaDragon({
      id: `osd-viewer${props.id}`,
      zoomPerScroll: 1.5,
      minZoomLevel: 0,
      maxZoomLevel: 7,
      constrainDuringPan: true,
      tileSources: props.tileSource,
      showNavigator: props.showNavigator,
      navigatorPosition: "ABSOLUTE",
      navigatorTop: "calc(100% - 10vh)",
      navigatorLeft: "calc(100% - 10vw)",
      showNavigationControl: false,
      preserveViewport: true,
      navigatorHeight: "10vh",
      navigatorWidth: "10vw",
    });

    const handleNav = () => {
      master = true;
      const zoom = viewer?.viewport.getZoom() as number;
      const coordinates = viewer?.viewport.getCenter() as CenterPoint;
      props.handleNav && props.handleNav(zoom, coordinates, props.id);
      master = false;
    };

    props.handleNav && viewer.addHandler("zoom", handleNav);
    props.handleNav && viewer.addHandler("pan", handleNav);

    return () => {
      viewer?.destroy();
    };
  });

  return <div ref={wrapper} className="h-full flex-grow" id={`osd-viewer${props.id}`} />;
});
