/* eslint-disable react-hooks/exhaustive-deps */
import {useContext, useEffect, useRef, useState} from "react";
import apiFetch from "../../../services/dataAccess";
import {useNavigate, useParams} from "react-router";
import {ImageUrlFormatter} from "../../../services/urlFormatter";
import {DateFormatter, DateTimeFormatter, ImageDownloadStringFormatter} from "../../../services/stringFormatter";
import {ProjectsContext} from "../../../context/ProjectsProvider";
import {ConciseRecording, ProjectAndCamParams} from "../../../types/types";
import {UserConfigurationContext} from "../../../context/UserConfigurationProvider";
import {getNearestTimestampIndex} from "../../../services/getNearestTimestamp";
import {SelectedTimestampContext} from "../../../context/SelectedTimestampProvider";
import {Pageloader} from "../../shared/Pageloader/Pageloader";
import {CompareImagesContext} from "../../../context/CompareImagesProvider";
import {CustomButton} from "../../shared/CustomButton/CustomButton";
import ReactSlider from "react-slider";
import Tippy from "@tippyjs/react";
import {CalendarDateTimeContext} from "../../../context/CalendarDateTimeProvider";
import {Calendar} from "../../shared/Calendar/Calendar";
import {sub} from "date-fns";
import {SelectedImages} from "./SelectedImages";
import {ImageFooter} from "../../shared/ImageFooter/ImageFooter";
import {ProjectBrandingClientLogo} from "../../shared/ProjectBrandingClientLogo/ProjectBrandingClientLogo";
import {ProjectBrandingPTLogo} from "../../shared/ProjectBrandingPTLogo/ProjectBrandingPTLogo";
import {StackToolbar} from "./StackToolbar";
import { HorizontalSlider } from "./HorizontalSlider";
import { SwipeEventData, useSwipeable } from "react-swipeable";
import { useTranslation } from "react-i18next";
const numberOfStackImages = 12;

export const ImageStack = () => {
  const navigate = useNavigate();
  const {projectName, cameraNumber} = useParams() as ProjectAndCamParams;
  const {t} = useTranslation();

  const {isPtAdmin} = useContext(UserConfigurationContext);
  const {state: projects} = useContext(ProjectsContext);
  const {selectedTimestamp, setSelectedTimestamp} = useContext(SelectedTimestampContext);
  const {startDate, endDate, startTime, endTime} = useContext(CalendarDateTimeContext);
  const {compareImages, showCompareImages, addCompareImage, removeCompareImage, clearCompareImages} =
    useContext(CompareImagesContext);

  const [recordings, setRecordings] = useState<ConciseRecording[]>([]);
  const [sliderIndex, setSliderIndex] = useState<number>(0);
  const [calendarStartDate, setCalendarStartDate] = useState<Date>();
  const [calendarEndDate, setCalendarEndDate] = useState<Date>();

  const project = projects[projectName];
  const camera = project.cameras[cameraNumber];

  // Fetch recordings once
  useEffect(() => {
    apiFetch(`cameras/${camera.id}/recordings/concise`)
      .then((r) => r.json())
      .then((r) => {
        setRecordings(r);
      });
  }, [projectName, cameraNumber]);

  // After recordings were fetched, find nearest cached timestamp or set on most current image
  useEffect(() => {
    if (!recordings.length) return;

    const startIndex: number = getNearestTimestampIndex(recordings, selectedTimestamp);
    setSliderIndex(startIndex);
    setSelectedTimestamp(recordings[startIndex].timestamp);

    //calculate start and end dates for the calendar
    const minutes = new Date().getTimezoneOffset();
    const startTime = sub(new Date(recordings[recordings.length - 1].timestamp), {minutes});
    const endTime = sub(new Date(recordings[0].timestamp) as Date, {minutes});

    setCalendarStartDate(startTime);
    setCalendarEndDate(endTime);
  }, [recordings]);

  //filter recordings by selected time period
  const filteredRecordings = recordings
    .filter((r) => {
      if (!endDate || !startDate) return true;

      const recordingDate = new Date(r.timestamp);
      return recordingDate.getTime() > startDate.getTime() && recordingDate.getTime() < endDate.getTime();
    })
    .filter((r) => {
      const recordingTime = new Date(r.timestamp);
      return recordingTime.getUTCHours() >= startTime && recordingTime.getUTCHours() < endTime;
    });

  //reset stack position after filtering
  useEffect(() => {
    if (!filteredRecordings.length) return;
    const startIndex: number = getNearestTimestampIndex(filteredRecordings, selectedTimestamp);
    setSliderIndex(startIndex);
    setSelectedTimestamp(filteredRecordings[startIndex].timestamp);
  }, [startTime, endTime, startDate, endDate, recordings]);

  const selectedRecordingIndex: number = getNearestTimestampIndex(filteredRecordings, selectedTimestamp);
  const selectedRecording: ConciseRecording = filteredRecordings[selectedRecordingIndex];
  const stackRef = useRef<HTMLDivElement>(null);

  const onSwiped = (e : SwipeEventData) => {
    if (e.dir === "Down") {
      if (selectedRecordingIndex < filteredRecordings.length - 1) {
        setSliderIndex(selectedRecordingIndex + 1);
        setSelectedTimestamp(filteredRecordings[selectedRecordingIndex + 1].timestamp);
      }
    } else if (e.dir === "Up") {
      if (selectedRecordingIndex > 0) {
        setSliderIndex(selectedRecordingIndex - 1);
        setSelectedTimestamp(filteredRecordings[selectedRecordingIndex - 1].timestamp);
      }
    }
  }
  const swipeHandler = useSwipeable({
    onSwiped: onSwiped,
  })

  // Update selected recording when scrolling in stack
  const handleWheel = (e: WheelEvent) => {
    e.preventDefault();

    if (e.deltaY < 0 && selectedRecordingIndex < filteredRecordings.length - 1) {
      setSliderIndex(selectedRecordingIndex + 1);
      setSelectedTimestamp(filteredRecordings[selectedRecordingIndex + 1].timestamp);
    }

    if (e.deltaY > 0 && selectedRecordingIndex > 0) {
      setSliderIndex(selectedRecordingIndex - 1);
      setSelectedTimestamp(filteredRecordings[selectedRecordingIndex - 1].timestamp);
    }
  };

  // Update selected recording when scrolling in stack
  const handleKey = (e: KeyboardEvent) => {
    e.preventDefault();

    if (e.key === "ArrowUp") {
      if (selectedRecordingIndex < filteredRecordings.length - 1) {
        setSliderIndex(selectedRecordingIndex + 1);
        setSelectedTimestamp(filteredRecordings[selectedRecordingIndex + 1].timestamp);
      }
    }

    if (e.key === "ArrowDown") {
      if (selectedRecordingIndex > 0) {
        setSliderIndex(selectedRecordingIndex - 1);
        setSelectedTimestamp(filteredRecordings[selectedRecordingIndex - 1].timestamp);
      }
    }
  };

  // Bind wheel handler to ref object
  useEffect(() => {
    stackRef.current?.addEventListener("wheel", handleWheel, {passive: false});
    window.addEventListener("keyup", handleKey);

    return () => {
      stackRef.current?.removeEventListener("wheel", handleWheel);
      window.removeEventListener("keyup", handleKey);
    };
  });

  if (!recordings.length || !selectedTimestamp) {
    return <Pageloader></Pageloader>;
  }

  const zoomURL = `/projects/${project.project}/${camera.cameraNumber}/zoom`;
  const dailyStackUrl = `/projects/${project.project}/${camera.cameraNumber}/dailys`;

  const imageDownloadURL = filteredRecordings.length
    ? ImageUrlFormatter(
        project.project,
        camera.cameraNumber,
        selectedRecording.timestamp,
        isPtAdmin || project.allowImageSrcDownload ? "full" : "medium",
      )
    : "";
  const mediaDownloadName = filteredRecordings.length
    ? ImageDownloadStringFormatter(project.name, camera.name, selectedRecording.timestamp)
    : "";

  const navigateToZoom = () => navigate(zoomURL);

  const moveSlider = (e: number) => {
    setSliderIndex(e);
  };

  const updateSlider = (e: number) => {
    setSelectedTimestamp(filteredRecordings[e].timestamp);
  };

  const addCurrentToCompareImages = () => {
    addCompareImage(selectedRecordingIndex);
  };

  return (
    <div
      ref={stackRef}
      className="container relative mx-auto sm:flex sm:justify-center gap-12 pt-8 pb-8"
    >
      <CustomButton
        action={() => navigate(-1)}
        buttonPositioning="absolute top-0 md:top-8 right-0 z-10"
        buttonPaddingClassName="p-1 m-1"
        iconProps={{
          icon: "XMarkIcon",
          iconClassName: "w-7 h-7",
        }}
        buttonBG="bg-panterra-800"
        tippyContent={t('tooltip:navigateBack')}
      ></CustomButton>

      <Calendar startDate={calendarStartDate} endDate={calendarEndDate} showTime={true} />

      {!filteredRecordings.length && (
        <div className={"mt-24 text-center"}>
          <p>{t('stackFilterNoImagesFound')}</p>
        </div>
      )}

      {!!filteredRecordings.length && (
        <>
          {/*Slider*/}
          <div className="hidden w-32 flex-col items-center sm:flex">
            <div className="whitespace-nowrap px-2">
              {DateFormatter(filteredRecordings[filteredRecordings.length - 1].timestamp)}
            </div>
            <div className="flex-grow py-5">
              <ReactSlider
                className="flex h-full w-8 flex-col items-center"
                trackClassName="w-1 rounded-full cursor-pointer stackCustomSlider-track" //coloring requirescustomSilder-track to be last item in className
                thumbClassName="bg-panterra-600 cursor-grabbing text-white whitespace-nowrap px-2 rounded-md"
                onChange={(e) => moveSlider(e as number)}
                onAfterChange={(e) => updateSlider(e as number)}
                orientation="vertical"
                value={sliderIndex}
                invert={true}
                min={0}
                max={filteredRecordings.length - 1}
                step={1}
                renderThumb={(props, state) => (
                  <Tippy
                    key={"tippy"}
                    content={DateTimeFormatter(filteredRecordings[state.valueNow].timestamp)}
                    delay={[0, 1000]}
                    hideOnClick={false}
                    placement={"right"}
                  >
                    <div {...props}>{DateFormatter(filteredRecordings[state.valueNow].timestamp)}</div>
                  </Tippy>
                )}
              />
            </div>
            <div className="whitespace-nowrap px-2">{DateFormatter(filteredRecordings[0].timestamp)}</div>
          </div>

          {/*Stack*/}
          <div {...swipeHandler} style={{maxWidth: "calc(100vh - 80px"}} className={"flex flex-grow items-center outline-none"}>
            <div className={"mx-auto flex-grow"}>
              <div
                className="relative w-full [transform-style:preserve-3d] [perspective:60rem] [perspective-origin:top]"
                style={{aspectRatio: "100/85"}}
              >
                {filteredRecordings
                  .slice(selectedRecordingIndex, selectedRecordingIndex + numberOfStackImages)
                  .map((recording, index) => (
                    <div
                      key={recording.timestamp}
                      style={{
                        transform: `translate3d(0%, ${-index * 1.6}%, ${-index * 5}rem)`,
                        pointerEvents: index === 0 ? "auto" : "none",
                      }}
                      className="group absolute bottom-0 w-full"
                    >
                      {index === 0 && (
                        <StackToolbar
                          type="image"
                          dailyStackUrl={dailyStackUrl}
                          imageZoomUrl={zoomURL}
                          mediaDownloadName={mediaDownloadName}
                          mediaDownloadUrl={imageDownloadURL}
                          addCurrentToCompareImages={addCurrentToCompareImages}
                        />
                      )}
                      {index === 0 && project.showPTLogoInTF && <ProjectBrandingPTLogo></ProjectBrandingPTLogo>}
                      {index === 0 && project.showClientLogoInTF && (
                        <ProjectBrandingClientLogo projectLogo={project.projectLogo}></ProjectBrandingClientLogo>
                      )}
                      <img
                        onDoubleClick={navigateToZoom}
                        className="w-full"
                        src={ImageUrlFormatter(project.project, camera.cameraNumber, recording.timestamp, "small")}
                        alt=""
                      />
                    </div>
                  ))}
              </div>

              <ImageFooter>
                <span>{`${project.name} - ${camera.name}`}</span>
                <span>{DateTimeFormatter(filteredRecordings[selectedRecordingIndex].timestamp)}</span>
              </ImageFooter>
            </div>
          </div>

          {/* Horizintal slider for mobile*/}
          <div className="sm:hidden pt-6 pb-2">
            <HorizontalSlider
              sliderIndex={sliderIndex}
              stack={filteredRecordings}
              moveSlider={moveSlider}
              updateSlider={updateSlider}
            />
          </div>

          {/* Selected Images  */}
          <SelectedImages
            recordings={filteredRecordings}
            project={project.project}
            cameraNumber={camera.cameraNumber}
            compareImages={compareImages}
            showCompareImages={showCompareImages}
            clearCompareImages={clearCompareImages}
            removeCompareImage={removeCompareImage}
          />
        </>
      )}
    </div>
  );
};
