import {format, utcToZonedTime} from "date-fns-tz";
import {parseISO} from "date-fns";

export const ImageUrlFormatter = (project: string, cam: string, dateTime: string, imageType: string): string => {
  const basePath: string = `https://content.panterra.de/projects/${project}/cam_${cam}/${format(
    utcToZonedTime(parseISO(dateTime), "UTC"),
    "yyyy/MM/dd/HH/mm",
    {timeZone: "UTC"},
  )}/${project}${cam}`;
  switch (imageType) {
    case "zoom":
      return `${basePath}_zdata/`;
    case "small":
      return `${basePath}_s.jpg`;
    case "medium":
      return `${basePath}_b.jpg`;
    case "full":
      return `${basePath}.jpg`;
    default:
      return `${basePath}_s.jpg`;
  }
};

export const VideoUrlFormatter = (project: string, cam: string, fileName: string, videoType: string): string => {
  const basePath: string = `https://content.panterra.de/projects/${project}/cam_${cam}/dailys/${fileName}`;
  switch (videoType) {
    case "daily":
      return `${basePath}.mp4`;
    case "preview":
      return `${basePath}.jpg`;
    default:
      return `${basePath}.mp4`;
  }
};
