import { PropsWithChildren } from "react";
import { useNavigate } from "react-router-dom";
import { CustomButton } from "../CustomButton/CustomButton"
import {useLocation} from "react-router-dom";


export const BlankPageWithBackButton = (props: PropsWithChildren) => {
  const navigate = useNavigate();
  const location = useLocation();
  return (
    <div className="container mx-auto relative">
        <div className="flex flex-row justify-center py-5">
          <CustomButton
            action={() => navigate(location.pathname.substring(0, location.pathname.lastIndexOf('/')))}
            buttonPositioning="absolute top-8 right-0"
            buttonPaddingClassName="p-1 m-1"
            buttonBG="bg-panterra-800"
            iconProps={{
              icon: "XMarkIcon",
              iconClassName: "w-7 h-7",
            }}
          ></CustomButton>
          {props.children}
        </div>
      </div>
  )
}
