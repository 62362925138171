import ReactSlider from "react-slider";
import { DateFormatter } from "../../../services/stringFormatter";
import { ConciseRecording, Daily } from "../../../types/types";

type HorizontalSliderProps = {
  stack: ConciseRecording[] | Daily[]
  sliderIndex: number
  moveSlider: (index : number) => void
  updateSlider: (index : number) => void
}

export const HorizontalSlider = (sliderProps: HorizontalSliderProps) => {
  return (
    <ReactSlider
      className="flex items-center"
      trackClassName="h-1 rounded-full cursor-pointer stackCustomSlider-track" //coloring requirescustomSilder-track to be last item in className
      thumbClassName="bg-panterra-600 cursor-grabbing text-white whitespace-nowrap px-1 rounded-md"
      onChange={(e) => sliderProps.moveSlider(e as number)}
      onAfterChange={(e) => sliderProps.updateSlider(e as number)}
      orientation="horizontal"
      value={sliderProps.sliderIndex}
      invert={true}
      min={0}
      max={sliderProps.stack.length -1}
      step={1}
      renderThumb={(props, state) => (
        <div {...props}>{DateFormatter(sliderProps.stack[state.valueNow].timestamp)}</div>
      )}
    />
  );
}
