import {ReactElement, useContext} from "react";
import {ProjectsContext} from "../../../context/ProjectsProvider";
import {Tab} from "@headlessui/react";
import {UserConfigurationContext} from "../../../context/UserConfigurationProvider";
import {LayoutContext} from "../../../context/LayoutProvider";
import {AdminHome} from "./AdminHome";
import { ProjectTiles } from "../ProjectTiles";
import { useTranslation } from "react-i18next";

export const Home = () => {
  const {state: projects} = useContext(ProjectsContext);
  const {isPtAdmin, hasProjects} = useContext(UserConfigurationContext);
  const {homeTabIndex, setHomeTabIndex} = useContext(LayoutContext);
  const {t} = useTranslation();

  const tabClasses =
    "inline-block px-2 py-1 text-lg rounded-lg text-panterra-100 hover:text-panterra-50 hover:bg-panterra-600 mx-2";
  const selectedTabClasses = "inline-block px-2 py-1 text-lg rounded-lg text-panterra-50 bg-panterra-800 mx-2";

  const userView = (
    <div className={"container mx-auto pt-8 pb-8"}>
      <Tab.Group defaultIndex={homeTabIndex} onChange={(index) => setHomeTabIndex(index)}>
        <Tab.List className={"flex flex-row justify-center pb-8"}>
          {Object.values(projects).map((project) => (
            <Tab key={project.project} className={"outline-none"}>
              {({selected}) => <span className={selected ? selectedTabClasses : tabClasses}>{project.name}</span>}
            </Tab>
          ))}
        </Tab.List>
        <Tab.Panels>
          {Object.values(projects).map((project) => (
            <Tab.Panel key={project.project}>
              <ProjectTiles project={project}></ProjectTiles>
            </Tab.Panel>
          ))}
        </Tab.Panels>
      </Tab.Group>
    </div>
  );

  let renderHome: ReactElement;
  // new User
  if (hasProjects === false) {
    renderHome = (
      <div className={"mx-auto min-h-full bg-panterra-900"}>
        <div className={"py-10 text-xl"}>
          {t('noProjectYetPleaseWait')}
        </div>
      </div>
    );
  }
  // Admin
  else if (isPtAdmin) {
    renderHome = <AdminHome />;
  }
  // Client
  else {
    renderHome = userView;
  }

  return renderHome;
};
