import {ReactComponent as PtLogo} from "../../assets/images/pt_logo_no_sub.svg";
import {Link} from "react-router-dom";
import {ProjectsContext} from "../../context/ProjectsProvider";
import {useContext} from "react";
import {UserConfigurationContext} from "../../context/UserConfigurationProvider";

type Props = {
  mayBeHidden?: boolean;
};

export const Logo = (props: Props) => {
  const {state: projects} = useContext(ProjectsContext);
  const {ptAdmin} = useContext(UserConfigurationContext);

  if (
    Object.values(projects).length > 0 &&
    !ptAdmin &&
    props.mayBeHidden &&
    !Object.values(projects).filter((p) => p.showPTLogoInTF).length
  ) {
    return <></>;
  }

  return (
    <Link to={"/"}>
      <PtLogo className={"block h-12 w-auto"} />
    </Link>
  );
};
