import { useEffect, useRef, HTMLAttributes } from 'react'

/* eslint-disable-next-line */
type Props = HTMLAttributes<HTMLDivElement> & {
  html: string
}

export function DangerouslySetHtmlContent({
  html,
  dangerouslySetInnerHTML,
  ...rest
  }: Props) {
    const divRef = useRef<HTMLDivElement>(null) // Create a container ref

    useEffect(() => {
      if (!html || !divRef.current) throw Object.assign(new Error("html prop cant't be null"), { code: 500 });
      const slotHtml = document.createRange().createContextualFragment(html) // Create a 'tiny' document and parse the html string
      divRef.current.innerHTML = '' // Clear the container
      divRef.current.appendChild(slotHtml) // Append the new content
    }, [html, divRef])

  return <div {...rest} ref={divRef}></div>
}

export default DangerouslySetHtmlContent
