/* eslint-disable react-hooks/exhaustive-deps */
import {createContext, PropsWithChildren, useEffect, useState} from "react";
import {useLocation} from "react-router-dom";

export type CompareImagesType = {
  compareImages: number[];
  showCompareImages: boolean;
  addCompareImage: (value: number) => void;
  removeCompareImage: (value: number) => void;
  clearCompareImages: () => void;
};

export const CompareImagesContext = createContext<CompareImagesType>({
  compareImages: [],
  showCompareImages: false,
  addCompareImage: (value) => {},
  removeCompareImage: (value) => {},
  clearCompareImages: () => {},
});

export const CompareImagesProvider = (props: PropsWithChildren<unknown>) => {
  let location = useLocation();
  const [currentProject, setCurrentProject] = useState("");
  const [compareImages, setCompareImages] = useState<number[]>([]);
  const [showCompareImages, setShowCompareImages] = useState<boolean>(false);

  // clear compare images after 0.5 sec sidebar fadeout
  const clearCompareImages = () => {
    setShowCompareImages(false);
    setTimeout(() => {
      setCompareImages([]);
    }, 500);
  };

  // add new image to tuple
  const addCompareImage = (index: number) => {
    setShowCompareImages(true);
    if (compareImages.length < 2 && !compareImages.includes(index)) {
      setCompareImages((current) => [...current, index]);
    } else if (compareImages.length === 2 && !compareImages.includes(index)) {
      setCompareImages((current) => [current[1], index]);
    } else {
      return;
    }
  };

  // remove image from tuple
  const removeCompareImage = (index: number) => {
    const newCompareImages = compareImages.filter((element) => {
      return element !== index;
    });
    if (newCompareImages.length === 0) clearCompareImages();
    else setCompareImages(newCompareImages);
  };

  // test url for project/camnr regex and reset image array when project changes
  useEffect(() => {
    const groupRegex = new RegExp(/projects\/(\w{5}\/\d{2})/);
    const match = location.pathname.match(groupRegex);
    if (match?.[1] && match[1] !== currentProject) {
      setCurrentProject(match[1]);
      clearCompareImages();
    }
  }, [location]);

  return (
    <CompareImagesContext.Provider
      value={{
        compareImages,
        showCompareImages,
        addCompareImage,
        removeCompareImage,
        clearCompareImages,
      }}
    >
      {props.children}
    </CompareImagesContext.Provider>
  );
};
