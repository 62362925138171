/* eslint-disable react-hooks/exhaustive-deps */
import {useContext, useEffect, useRef, useState} from "react";
import apiFetch from "../../../services/dataAccess";
import {useNavigate, useParams} from "react-router";
import {DateFormatter, VideoDownloadStringFormatter} from "../../../services/stringFormatter";
import {ProjectsContext} from "../../../context/ProjectsProvider";
import {Daily, ProjectAndCamParams} from "../../../types/types";
import {getNearestTimestampIndex} from "../../../services/getNearestTimestamp";
import {SelectedTimestampContext} from "../../../context/SelectedTimestampProvider";
import {Pageloader} from "../../shared/Pageloader/Pageloader";
import {VideoUrlFormatter} from "../../../services/urlFormatter";
import {PlayerElement} from "../../shared/PlayerElement/PlayerElement";
import {DailyMergerModal, IDailyMergerModal} from "../Video/DailyMergerModal";
import {CustomButton} from "../../shared/CustomButton/CustomButton";
import {ProjectBrandingPTLogo} from "../../shared/ProjectBrandingPTLogo/ProjectBrandingPTLogo";
import {ProjectBrandingClientLogo} from "../../shared/ProjectBrandingClientLogo/ProjectBrandingClientLogo";
import ReactSlider from "react-slider";
import {StackToolbar} from "./StackToolbar";
import {ImageFooter} from "../../shared/ImageFooter/ImageFooter";
import {Calendar} from "../../shared/Calendar/Calendar";
import {CalendarDateTimeContext} from "../../../context/CalendarDateTimeProvider";
import {sub} from "date-fns";
import { SwipeEventData, useSwipeable } from "react-swipeable";
import { HorizontalSlider } from "./HorizontalSlider";
import { useTranslation } from "react-i18next";

const numberOfStackImages = 12;

export const DailyStack = () => {
  const navigate = useNavigate();
  const {t} = useTranslation();
  const {state: projects} = useContext(ProjectsContext);
  const {projectName, cameraNumber} = useParams() as ProjectAndCamParams;

  const [dailys, setDailys] = useState<Daily[]>([]);
  const {selectedTimestamp, setSelectedTimestamp} = useContext(SelectedTimestampContext);
  const [sliderIndex, setSliderIndex] = useState<number>(0);

  const dailyMerger = useRef<IDailyMergerModal>(null);

  const project = projects[projectName];
  const camera = project.cameras[cameraNumber];

  const {startDate, endDate} = useContext(CalendarDateTimeContext);
  const [calendarStartDate, setCalendarStartDate] = useState<Date>();
  const [calendarEndDate, setCalendarEndDate] = useState<Date>();

  // Fetch dailys once
  useEffect(() => {
    apiFetch(`cameras/${camera.id}/dailys`)
      .then((r) => r.json())
      .then((r) => {
        setDailys(r);
      });
  }, [projectName, cameraNumber]);

  // After dailys were fetched, find nearest cached timestamp or set on most current image
  useEffect(() => {
    if (!dailys.length) return;

    const startIndex: number = getNearestTimestampIndex(dailys, selectedTimestamp);
    setSliderIndex(startIndex);
    setSelectedTimestamp(dailys[startIndex].timestamp);

    //calculate start and end dates for the calendar
    const minutes = new Date().getTimezoneOffset();
    const startTime = sub(new Date(dailys[dailys.length - 1].timestamp), {minutes});
    const endTime = sub(new Date(dailys[0].timestamp) as Date, {minutes});

    setCalendarStartDate(startTime);
    setCalendarEndDate(endTime);
  }, [dailys]);

  //filter dailys by selected time period
  const filteredDailys = dailys
    .filter((item, index) => {
      //ensure only one daily per day (avoid problems with find nearest timestamp)
      return index === dailys.findIndex((daily) => daily.timestamp === item.timestamp);
    })
    .filter((r) => {
      if (!endDate || !startDate) return true;

      const dailyDate = new Date(r.timestamp);
      return dailyDate.getTime() > startDate.getTime() && dailyDate.getTime() < endDate.getTime();
    });

  //reset stack position after filtering
  useEffect(() => {
    if (!filteredDailys.length) return;
    const startIndex: number = getNearestTimestampIndex(filteredDailys, selectedTimestamp);

    setSliderIndex(startIndex);
    setSelectedTimestamp(filteredDailys[startIndex].timestamp);
  }, [startDate, endDate, dailys]);

  const selectedDailyIndex: number = getNearestTimestampIndex(filteredDailys, selectedTimestamp);
  const selectedDaily: Daily = filteredDailys[selectedDailyIndex];

  const stackRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    setSliderIndex(selectedDailyIndex);
  }, [selectedDailyIndex]);

  const onSwiped = (e : SwipeEventData) => {
    if (e.dir === "Down") {
      if (selectedDailyIndex < filteredDailys.length - 1) {
        setSliderIndex(selectedDailyIndex + 1);
        setSelectedTimestamp(filteredDailys[selectedDailyIndex + 1].timestamp);
      }
    } else if (e.dir === "Up") {
      if (selectedDailyIndex > 0) {
        setSliderIndex(selectedDailyIndex - 1);
        setSelectedTimestamp(filteredDailys[selectedDailyIndex - 1].timestamp);
      }
    }
  }
  const swipeHandler = useSwipeable({
    onSwiped: onSwiped,
  })

  // Update selected recording when scrolling in stack
  const handleWheel = (e: WheelEvent) => {
    e.preventDefault();

    if (e.deltaY < 0 && selectedDailyIndex < filteredDailys.length - 1) {
      setSliderIndex(selectedDailyIndex + 1);
      setSelectedTimestamp(filteredDailys[selectedDailyIndex + 1].timestamp);
    }

    if (e.deltaY > 0 && selectedDailyIndex > 0) {
      setSliderIndex(selectedDailyIndex - 1);
      setSelectedTimestamp(filteredDailys[selectedDailyIndex - 1].timestamp);
    }
  };

  // Update selected recording when scrolling in stack
  const handleKey = (e: KeyboardEvent) => {
    e.preventDefault();

    if (e.key === "ArrowUp") {
      if (selectedDailyIndex < filteredDailys.length - 1) {
        setSliderIndex(selectedDailyIndex + 1);
        setSelectedTimestamp(filteredDailys[selectedDailyIndex + 1].timestamp);
      }
    }

    if (e.key === "ArrowDown") {
      if (selectedDailyIndex > 0) {
        setSliderIndex(selectedDailyIndex - 1);
        setSelectedTimestamp(filteredDailys[selectedDailyIndex - 1].timestamp);
      }
    }
  };

  // Bind wheel handler to ref object
  useEffect(() => {
    stackRef.current?.addEventListener("wheel", handleWheel, {passive: false});
    window.addEventListener("keyup", handleKey);

    return () => {
      stackRef.current?.removeEventListener("wheel", handleWheel);
      window.removeEventListener("keyup", handleKey);
    };
  });

  if (!dailys.length || !selectedTimestamp) {
    return <Pageloader></Pageloader>;
  }

  const selectedVideoURL = VideoUrlFormatter(projectName, cameraNumber, selectedDaily.fileName, "daily");
  const selectedPreviewURL = VideoUrlFormatter(projectName, cameraNumber, selectedDaily.fileName, "preview");
  const mediaDownloadName = VideoDownloadStringFormatter(project.name, camera.name, selectedDaily.timestamp);
  const imageStackURL = `/projects/${project.project}/${camera.cameraNumber}`;

  const openDailyMerger = () => {
    dailyMerger.current?.setModalOpen(true);
  };

  const moveSlider = (e: number) => {
    setSliderIndex(e);
  };

  const updateSlider = (e: number) => {
    setSelectedTimestamp(filteredDailys[e].timestamp);
  };

  return (
    <div ref={stackRef} className="container relative mx-auto sm:flex sm:justify-center gap-12 pt-8 pb-8">
      <CustomButton
        action={() => navigate(-1)}
        buttonPositioning="absolute top-0 right-0 md:top-8 z-10"
        buttonPaddingClassName="p-1 m-1"
        iconProps={{
          icon: "XMarkIcon",
          iconClassName: "w-7 h-7",
        }}
        buttonBG="bg-panterra-800"
        tippyContent={t('tooltip:navigateBack')}
      ></CustomButton>

      <Calendar startDate={calendarStartDate} endDate={calendarEndDate} />

      {/* *Videomixer modal */}
      <DailyMergerModal dailys={dailys} ref={dailyMerger} />

      {/*Slider*/}
      <div className="hidden w-32 flex-col items-center sm:flex">
        <div className="whitespace-nowrap px-2">
          {DateFormatter(filteredDailys[filteredDailys.length - 1].timestamp)}
        </div>
        <div className="flex-grow py-5">
          <ReactSlider
            className="flex h-full w-8 flex-col items-center"
            trackClassName="w-1 rounded-full cursor-pointer stackCustomSlider-track" //coloring requirescustomSilder-track to be last item in className
            thumbClassName="bg-panterra-600 cursor-grabbing text-white whitespace-nowrap px-2 rounded-md"
            onChange={(e) => moveSlider(e as number)}
            onAfterChange={(e) => updateSlider(e as number)}
            orientation="vertical"
            value={sliderIndex}
            invert={true}
            min={0}
            max={filteredDailys.length - 1}
            step={1}
            renderThumb={(props, state) => (
              <div {...props}>{DateFormatter(filteredDailys[state.valueNow].timestamp)}</div>
            )}
          />
        </div>
        <div className="whitespace-nowrap px-2">{DateFormatter(filteredDailys[0].timestamp)}</div>
      </div>

      {/*Stack*/}
      <div {...swipeHandler} style={{maxWidth: "calc(100vh - 80px"}} className={"flex flex-grow items-center outline-none"}>
        <div className={"mx-auto flex-grow"}>
          <div
            className="relative w-full [transform-style:preserve-3d] [perspective:60rem] [perspective-origin:top]"
            style={{aspectRatio: "100/85"}}
          >
            {filteredDailys.slice(selectedDailyIndex, selectedDailyIndex + numberOfStackImages).map((daily, index) => (
              <div
                key={`${daily.timestamp}-${daily.fileName}`}
                style={{
                  transform: `translate3d(0%, ${-index * 1.6}%, ${-index * 5}rem)`,
                  pointerEvents: index === 0 ? "auto" : "none",
                }}
                className="group absolute bottom-0 w-full border-t border-l border-r border-primary-700"
              >
                {index === 0 && project.showPTLogoInTF && <ProjectBrandingPTLogo></ProjectBrandingPTLogo>}
                {index === 0 && project.showClientLogoInTF && (
                  <ProjectBrandingClientLogo projectLogo={project.projectLogo}></ProjectBrandingClientLogo>
                )}
                {index === 0 ? (
                  <>
                    <StackToolbar
                      type="daily"
                      imageStackUrl={imageStackURL}
                      mediaDownloadName={mediaDownloadName}
                      mediaDownloadUrl={selectedVideoURL}
                      openDailyMerger={openDailyMerger}
                    />
                    <PlayerElement
                      videoURL={selectedVideoURL}
                      previewURL={selectedPreviewURL} />
                  </>
                ) : (
                  <img
                    className="w-full"
                    src={VideoUrlFormatter(projectName, cameraNumber, daily.fileName, "preview")}
                    alt=""
                  />
                )}
              </div>
            ))}
          </div>
          <ImageFooter className="border-b border-l border-r border-primary-700">
            <span>{`${project.name} - ${camera.name}`}</span>
            <span>{DateFormatter(filteredDailys[selectedDailyIndex].timestamp)}</span>
          </ImageFooter>
        </div>
      </div>

      {/* Horizintal slider for mobile*/}
      <div className="sm:hidden pt-6">
        <HorizontalSlider
          sliderIndex={sliderIndex}
          stack={filteredDailys}
          moveSlider={moveSlider}
          updateSlider={updateSlider}
        />
      </div>

    </div>
  );
};
