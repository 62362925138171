import {Link} from "react-router-dom";
import {ReactElement} from "react";

type Props = {
  label: string;
  link: string;
  icon?: ReactElement;
};

export const SubMenuItem = (props: Props) => {
  return (
    <Link
      to={props.link}
      className={
        "ml-16 flex rounded-lg p-2 pl-4 text-panterra-100 transition-all hover:bg-panterra-600 hover:pl-6 hover:text-panterra-50"
      }
    >
      {props.icon}
      <span className={"ml-3 align-middle transition-all"}>{props.label}</span>
    </Link>
  );
};
