import { Image, Project} from "../../../types/types"
import { ImageFooter } from "../../shared/ImageFooter/ImageFooter"
import { ImageWidgetToolbar } from "./ImageWidgetToolbar"

export type ImageWidgetProps = {
  image: Image
  project: Project
}

export const ImageWidget = (props: ImageWidgetProps) => {

  return (
    <div className="group relative w-full">
      <ImageWidgetToolbar
        imageZoomUrl={`/projects/${props.project.project}/image_widgets/${props.image.id}`}
        mediaDownloadName={`${props.image.name.replace(/\s/g, '')}.jpg`}
        mediaDownloadUrl={`${process.env.REACT_APP_API_ENTRYPOINT}assets/image_widgets/${props.image.image}`}
      />
      <div
        style={{
          aspectRatio: "870/580",
        }}>
          <img
            className="object-cover w-full h-full"
            src={`${process.env.REACT_APP_API_ENTRYPOINT}assets/image_widgets/${props.image.image}`}
            alt=""
          ></img>
      </div>
      <ImageFooter>
        <span
          className="whitespace-nowrap overflow-hidden overflow-ellipsis">
          {props.project.name} - {props.image.name}
        </span>
      </ImageFooter>
    </div>
  )
}
