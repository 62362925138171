export type CustomToolbarProps = {
  left: JSX.Element[];
  right: JSX.Element[];
};

export const CustomToolbar = (props: CustomToolbarProps) => {
  return (
    <div className="invisible absolute inset-x-0 z-10 flex bg-panterra-800 p-2 opacity-0 transition-opacity group-hover:visible duration-500 hover:!opacity-100 group-hover:opacity-50">
      {props.left}
      <div className={"flex-grow"}></div>
      {props.right}
    </div>
  );
};
