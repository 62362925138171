import keycloak from "./keycloak";

const MIME_TYPE = "application/json";
const ENTRYPOINT = process.env.REACT_APP_API_ENTRYPOINT;

export default async function apiFetch(id: string, options: any = {}) {
  if (typeof options.headers === "undefined") options.headers = new Headers();
  if (options.headers.get("Accept") === null) options.headers.set("Accept", MIME_TYPE);

  if (keycloak.token && options.headers.get("Authorization") === null)
    options.headers.set("Authorization", `Bearer ${keycloak.token}`);

  if (
    options.body !== "undefined" &&
    !(options.body instanceof FormData) &&
    options.headers.get("Content-Type") === null
  )
    options.headers.set("Content-Type", MIME_TYPE);

  // @ts-ignore
  return global.fetch(new URL(id, ENTRYPOINT), options).then((response) => {
    if (response.ok) return response;

    return response.json().then(() => {
      throw new Error(response.statusText || "An error occurred.");
    });
  });
}
