/* eslint-disable react-hooks/exhaustive-deps */
import ReactPlayer from "react-player";
import {useContext, useEffect, useState} from "react";
import apiFetch from "../../../services/dataAccess";
import {useParams} from "react-router";
import {ProjectsContext} from "../../../context/ProjectsProvider";
import {Daily, ProjectAndCamParams} from "../../../types/types";
import "rc-slider/assets/index.css";
import {getNearestTimestamp, getNearestTimestampIndex} from "../../../services/getNearestTimestamp";
import {SelectedTimestampContext} from "../../../context/SelectedTimestampProvider";
import {Pageloader} from "../../shared/Pageloader/Pageloader";
import {VideoUrlFormatter} from "../../../services/urlFormatter";

// this thing is currently not used. I'm leaving it here as a dummy template because later we might need such a Component

export const DailyPlayer = () => {
  const {state: projects} = useContext(ProjectsContext);

  const {projectName, cameraNumber} = useParams() as ProjectAndCamParams;

  const [dailys, setDailys] = useState<Daily[]>([]);
  const {selectedTimestamp, setSelectedTimestamp} = useContext(SelectedTimestampContext);
  const project = projects[projectName];
  const camera = project.cameras[cameraNumber];

  // Fetch dailys once
  useEffect(() => {
    apiFetch(`cameras/${camera.id}/dailys`)
      .then((r) => r.json())
      .then((r) => {
        setDailys(r);
      });
  }, [projectName, cameraNumber]);

  // After dailys were fetched, find nearest cached timestamp or set on most current image
  useEffect(() => {
    if (!dailys.length) return;

    if (selectedTimestamp) {
      setSelectedTimestamp(getNearestTimestamp(dailys, selectedTimestamp));
      return;
    }

    setSelectedTimestamp(dailys[0].timestamp);
  }, [dailys]);

  if (!dailys.length || !selectedTimestamp) {
    return <Pageloader></Pageloader>;
  }

  const selectedDailyIndex: number = getNearestTimestampIndex(dailys, selectedTimestamp);
  const selectedDaily: Daily = dailys[selectedDailyIndex];

  return (
    <ReactPlayer
      config={{file: {attributes: {controlsList: "nodownload"}}}}
      onContextMenu={(e: MouseEvent) => e.preventDefault()}
      url={VideoUrlFormatter(projectName, cameraNumber, selectedDaily.fileName, "daily")}
      light={VideoUrlFormatter(projectName, cameraNumber, selectedDaily.fileName, "preview")}
      controls
      width="100%"
      height="90%"
    ></ReactPlayer>
  );
};
