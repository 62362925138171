import {ProjectLogo} from "../../../types/types";
import classNames from "classnames";

type Props = {
  className?: string;
  projectLogo: ProjectLogo;
};

export const ProjectBrandingClientLogo = (props: Props) => {

  if (!props.projectLogo) {
    return <></>;
  }
  const path = `${process.env.REACT_APP_API_ENTRYPOINT}assets/logos_project/${props.projectLogo.projectLogo}`;


  return (
    <div
      className={classNames(
        {"pointer-events-none absolute top-5 right-5 z-20 h-8 transition-opacity duration-500 group-hover:opacity-0" : !props.className},
        {[`${props.className}`]: props.className}
      )}
    >
      <img src={path} alt="" className={"h-full"} />
    </div>
  );
};
