/* eslint-disable react-hooks/exhaustive-deps */
import {useNavigate, useParams} from "react-router";
import {TileSourceOptions} from "openseadragon";
import {useContext, useEffect, useMemo, useRef, useState} from "react";
import apiFetch from "../../../services/dataAccess";
import {ProjectsContext} from "../../../context/ProjectsProvider";
import {ConciseRecording, ProjectAndCamParams} from "../../../types/types";
import {ImageUrlFormatter} from "../../../services/urlFormatter";
import {ITileViewer, TileViewer} from "./TileViewer";
import {Pageloader} from "../../shared/Pageloader/Pageloader";
import {DateTimeFormatter} from "../../../services/stringFormatter";
import {CustomButton} from "../../shared/CustomButton/CustomButton";
import screenfull from "screenfull";
import {ProjectBrandingPTLogo} from "../../shared/ProjectBrandingPTLogo/ProjectBrandingPTLogo";
import {ProjectBrandingClientLogo} from "../../shared/ProjectBrandingClientLogo/ProjectBrandingClientLogo";
import {ControlBar} from "./ControlBar";
import {SelectedTimestampContext} from "../../../context/SelectedTimestampProvider";
import {getNearestTimestampIndex} from "../../../services/getNearestTimestamp";
import {OSDLegend} from "./OSDLegend";
import Tippy from "@tippyjs/react";
import {QuestionMarkCircleIcon} from "@heroicons/react/24/solid";
import { useTranslation } from "react-i18next";
import './modal.css';

export const SingleZoom = () => {
  const navigate = useNavigate();
  const {projectName: currentProject, cameraNumber} = useParams<ProjectAndCamParams>() as ProjectAndCamParams;
  const {state: projects} = useContext(ProjectsContext);
  const [isFullScreen, setIsFullScreen] = useState<boolean>(false);
  const {selectedTimestamp, setSelectedTimestamp} = useContext(SelectedTimestampContext);
  const {t} = useTranslation();

  const [recordings, setRecordings] = useState<ConciseRecording[]>([]);
  const [tileSource, setTileSource] = useState<Partial<TileSourceOptions>>({});
  const [index, setIndex] = useState(0);

  const project = projects[currentProject];
  const camera = project.cameras[cameraNumber];

  const tileViewer = useRef<ITileViewer>(null);
  const wrapper = useRef<HTMLDivElement>(null);

  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    apiFetch(`cameras/${camera.id}/recordings/concise`)
      .then((response) => response.json())
      .then((data) => {
        setRecordings(data);
      });
  }, []);

  useEffect(() => {
    if (screenfull.isEnabled) {
      screenfull.on("change", () => setIsFullScreen(screenfull.isFullscreen));
    }
    return () => {
      if (screenfull.isEnabled) {
        screenfull.off("change", () => setIsFullScreen(screenfull.isFullscreen));
      }
    };
  });

  useEffect(() => {
    if (!recordings.length) return;

    const startIndex: number = selectedTimestamp ? getNearestTimestampIndex(recordings, selectedTimestamp) : 0;
    const tileSource: TileSourceOptions = generateTileSource(recordings[startIndex].timestamp);
    setTileSource(tileSource);
    setIndex(startIndex);
  }, [recordings]);

  const generateTileSource = (datetime: string): TileSourceOptions & {type: string; tilesUrl: string} => {
    return {
      type: "zoomifytileservice",
      width: camera.pixelWidth,
      height: camera.pixelHeight,
      tilesUrl: ImageUrlFormatter(currentProject, cameraNumber, datetime, "zoom"),
    };
  };

  const tileViewerElement = useMemo(() => {
    return <TileViewer showNavigator={true} ref={tileViewer} tileSource={tileSource} id={1}></TileViewer>;
  }, [tileSource]);

  const goToImage = (index: number) => {
    setIsLoading(true);
    tileViewer.current?.useNewTileSource(generateTileSource(recordings[index].timestamp), () => { setIsLoading(false)});
    setSelectedTimestamp(recordings[index].timestamp);
    setIndex(index);
  };

  const toggleFullscreen = () => {
    if (screenfull.isEnabled && wrapper.current) {
      screenfull.toggle(wrapper.current);
    }
  };

  if (!Object.keys(tileSource).length) {
    return <Pageloader></Pageloader>;
  }

  return (
    // mx-auto container relative flex flex-row justify-center flex-grow gap-12 pt-8 pb-8
    <div className="container mx-auto flex h-full flex-col pt-8 pb-8">
      {/* One Tileviewer */}
      <div ref={wrapper} className={"relative flex-grow bg-panterra-900"}>
        <div className={`${isLoading ? 'modal' : ''}`}>
          {isLoading && <div className="modal-content"><Pageloader></Pageloader></div>}
        </div>

        {tileViewerElement}

        {/* Controls and Overlays */}
        {project.showPTLogoInTF && <ProjectBrandingPTLogo className={"!bottom-12 !left-5"}></ProjectBrandingPTLogo>}
        {project.showClientLogoInTF && (
          <ProjectBrandingClientLogo
            projectLogo={project.projectLogo}
            className={"absolute top-2 right-24 z-20 h-8"}
          ></ProjectBrandingClientLogo>
        )}

        <div className="absolute bottom-0 left-0 bg-panterra-800 px-1 text-lg">
          {DateTimeFormatter(recordings[index].timestamp)}
        </div>
        {index > 0 && (
          <CustomButton
            action={() => goToImage(index - 1)}
            buttonPositioning="absolute right-0 top-1/2"
            buttonPaddingClassName="p-1 m-1"
            buttonBG="bg-panterra-800"
            tippyContent={t('tooltip:nextPage')}
            iconProps={{
              icon: "ChevronRightIcon",
              iconClassName: "w-9 h-9",
            }}
          ></CustomButton>
        )}
        {index < recordings.length - 1 && (
          <CustomButton
            action={() => goToImage(index + 1)}
            buttonPositioning="absolute left-0 top-1/2"
            buttonPaddingClassName="p-1 m-1"
            buttonBG="bg-panterra-800"
            tippyContent={t('tooltip:previousPage')}
            iconProps={{
              icon: "ChevronLeftIcon",
              iconClassName: "w-9 h-9",
            }}
          ></CustomButton>
        )}
        <ControlBar
          isFullScreen={isFullScreen}
          goHome={() => tileViewer.current?.goHome()}
          zoomIn={() => tileViewer.current?.zoomIn()}
          zoomOut={() => tileViewer.current?.zoomOut()}
          toggleFullScreen={toggleFullscreen}
        ></ControlBar>
        <Tippy content={<OSDLegend />} placement={"bottom-end"}>
          <div className="absolute top-0 right-11 m-1 rounded-md bg-panterra-800 p-1 hover:bg-panterra-700">
            <QuestionMarkCircleIcon className="h-7 w-7" />
          </div>
        </Tippy>
        <CustomButton
          action={() => navigate(-1)}
          buttonPositioning="absolute top-0 right-0"
          buttonPaddingClassName="p-1 m-1"
          buttonBG="bg-panterra-800"
          tippyContent={t('tooltip:navigateBack')}
          iconProps={{
            icon: "XMarkIcon",
          }}
        ></CustomButton>
      </div>
    </div>
  );
};
