import {SyntheticEvent} from "react";
import {Icon, IconProps} from "../Icon/Icon";
import classNames from "classnames";
import Tippy from "@tippyjs/react";
import {Link} from "react-router-dom";

export type CustomButtonProps = {
  link?: string;
  href?: string;
  download?: string | boolean;
  replace?: boolean;
  action?: (e: SyntheticEvent) => void;
  buttonDisabled?: boolean;
  buttonPaddingClassName?: String;
  buttonHoverColorClassName?: String;
  buttonSelected?: boolean;
  buttonPositioning?: String;
  buttonBG?: String;
  tippyContent?: String;
  iconProps: IconProps;
};

export const CustomButton = (props: CustomButtonProps): JSX.Element => {
  const buttonClassNames = classNames(
    "rounded-md",
    {[`${props.buttonPaddingClassName}`]: props.buttonPaddingClassName, "p-2 mx-1": !props.buttonPaddingClassName},
    {
      [`${props.buttonHoverColorClassName}`]: props.buttonHoverColorClassName && !props.buttonSelected,
      "hover:bg-panterra-700": !props.buttonHoverColorClassName && !props.buttonSelected,
    },
    {"bg-panterra-600": props.buttonSelected},
    {"opacity-50": props.buttonDisabled},
    {"cursor-not-allowed": props.buttonDisabled},
    {[`${props.buttonBG}`]: props.buttonBG},
  );
  const buttonPositioning = classNames({[`${props.buttonPositioning}`]: props.buttonPositioning});

  let customButton = <></>;
  if (props.action) {
    customButton = (
      <Tippy content={props.tippyContent} disabled={!props.tippyContent} delay={[1000, 200]}>
        <div className={buttonPositioning}>
          <button disabled={props.buttonDisabled} onClick={props.action} className={`${buttonClassNames} block`}>
            <Icon {...props.iconProps}></Icon>
          </button>
        </div>
      </Tippy>
    );
  } else if (props.link) {
    customButton = (
      <Tippy content={props.tippyContent} disabled={!props.tippyContent} delay={[1000, 200]}>
        <Link
          to={props.link}
          replace={props.replace ? props.replace : false}
          className={`${buttonPositioning} ${buttonClassNames}`}
        >
          <Icon {...props.iconProps}></Icon>
        </Link>
      </Tippy>
    );
  } else if (props.href) {
    customButton = (
      <>
        {props.buttonDisabled && (
          <Tippy content={props.tippyContent} disabled={!props.tippyContent} delay={[1000, 200]}>
            <span className={`${buttonPositioning} ${buttonClassNames} "block cursor-not-allowed`}>
              <Icon {...props.iconProps}></Icon>
            </span>
          </Tippy>
        )}

        {!props.buttonDisabled && (
          <Tippy content={props.tippyContent} disabled={!props.tippyContent} delay={[1000, 200]}>
            <a href={props.href} download={props.download} className={`block ${buttonPositioning} ${buttonClassNames}`}>
              <Icon {...props.iconProps}></Icon>
            </a>
          </Tippy>
        )}
      </>
    );
  }

  return customButton;
};
