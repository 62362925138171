import {PropsWithChildren} from "react";

type ImageFooterProps = {
  className?: string;
};

export const ImageFooter = (props: PropsWithChildren<ImageFooterProps>) => {
  return (
    <div className={`text-s flex place-content-between bg-panterra-700 p-1 text-white ${props.className}`}>
      {props.children}
    </div>
  );
};
