import {ProjectsContext} from "../../context/ProjectsProvider";
import {useContext} from "react";
import {UserConfigurationContext} from "../../context/UserConfigurationProvider";

export const ProjectLogos = () => {
  const {state: projects} = useContext(ProjectsContext);
  const {isPtAdmin} = useContext(UserConfigurationContext);

  // Don't show project logos for admin usersDon't show project logos for admin users
  if (isPtAdmin) {
    return <></>;
  }

  const logos = [
    ...new Set(
      Object.values(projects)
        .filter((p) => p.projectLogo && p.showClientLogoInTF)
        .map(
          (project) =>
            `${process.env.REACT_APP_API_ENTRYPOINT}/assets/logos_project/${project.projectLogo.projectLogo}`,
        ),
    ),
  ];

  return (
    <>
      {logos.map((logo) => (
        <img key={logo} alt="" className={"block h-8"} src={logo} />
      ))}
    </>
  );
};
