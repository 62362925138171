import {createContext, Dispatch, PropsWithChildren, SetStateAction, useState} from "react";

export type SelectedTimestampContextType = {
  selectedTimestamp: string;
  setSelectedTimestamp: Dispatch<SetStateAction<string>>;
};

export const SelectedTimestampContext = createContext<SelectedTimestampContextType>({
  selectedTimestamp: "",
  setSelectedTimestamp: (value) => {},
});

export const SelectedTimestampProvider = (props: PropsWithChildren<unknown>) => {
  const [selectedTimestamp, setSelectedTimestamp] = useState<string>("");

  return (
    <SelectedTimestampContext.Provider value={{selectedTimestamp, setSelectedTimestamp}}>
      {props.children}
    </SelectedTimestampContext.Provider>
  );
};
