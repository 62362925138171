/* eslint-disable react-hooks/exhaustive-deps */
import {createContext, PropsWithChildren, useEffect, useState} from "react";
import {useKeycloak} from "@react-keycloak/web";

export const UserConfigurationContext = createContext<any>(null);

export const UserConfigurationProvider = (props: PropsWithChildren<unknown>) => {
  const {keycloak} = useKeycloak();
  const [isPtAdmin, setIsPtAdmin] = useState<boolean>();
  const [hasProjects, setHasProjects] = useState<boolean | null>(null);
  const [userName, setUserName] = useState<string>("");
  const [emailAddress, setEmailAddress] = useState<string>("");
  const [hasPreviewAccess, setHasPreviewAccess] = useState<boolean>();

  useEffect(() => {
    const ptAdmin = keycloak.tokenParsed?.is_admin ? keycloak.tokenParsed.is_admin : false;
    const user = keycloak.tokenParsed?.preferred_username ? keycloak.tokenParsed.preferred_username : "";
    const email = keycloak.tokenParsed?.email ? keycloak.tokenParsed.email : "";
    // Be sure to add has_preview_access to the Mappers of the Keycloak Client "timefactory"
    // admin always has preview access
    const previewAccess = keycloak.tokenParsed?.has_preview_access ? keycloak.tokenParsed.has_preview_access : ptAdmin;
    setIsPtAdmin(ptAdmin);
    setUserName(user);
    setEmailAddress(email);
    setHasPreviewAccess(previewAccess);
  }, [keycloak.tokenParsed, keycloak.authenticated]);

  return (
    <UserConfigurationContext.Provider
      value={{
        isPtAdmin,
        userName,
        emailAddress,
        hasProjects,
        setHasProjects,
        hasPreviewAccess,
      }}
    >
      {props.children}
    </UserConfigurationContext.Provider>
  );
};
