import { forwardRef, Fragment, useEffect, useImperativeHandle, useState } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { CustomButton } from "../../shared/CustomButton/CustomButton";
import { useTranslation } from "react-i18next";
import { VideoUrlFormatter } from "../../../services/urlFormatter";
import Draggable from 'react-draggable';
import { useResizeDetector } from 'react-resize-detector';

export interface IPortraitSelectorModal {
  setModalOpen: (modalOpen: boolean) => void;
}

type PortraitSelectorModalProps = {
  fileName: string;
  projectName: string;
  cameraNumber: string;
  position: number;
  setPosition: (position: number) => void;
};

export const PortraitSelectorModal = forwardRef((props: PortraitSelectorModalProps, ref) => {
  const [open, setOpen] = useState(false);
  const { t } = useTranslation();

  // forward ref to open the modal from stack
  useImperativeHandle(ref, () => ({
    setModalOpen(modalOpen: boolean) {
      setOpen(modalOpen);
    },
  }));

  const updatePosition = (x: number) => {
    props.setPosition(Math.round(x * 100 / maxWidth));
  };

  const handleDrag = (e: any, ui: any) => {
    setPositionX(ui.x);
    updatePosition(ui.x);
  };
  const computeWidth = (width: number) => {
    return width * 9 / 16;
  };

  const [previewImageSize, setPreviewImageSize] = useState({ width: 870, height: 580 });
  const [areaWidth, setAreaWidth] = useState(computeWidth(previewImageSize.height));
  const [maxWidth, setMaxWidth] = useState(previewImageSize.width - areaWidth);
  const [positionX, setPositionX] = useState(maxWidth * props.position / 100);

  const { width: imgWidth, height: imgHeight, ref: imgRef } = useResizeDetector();

  useEffect(() => {
    if (imgWidth && imgHeight) {
      const newWidth = computeWidth(imgHeight);
      const newMaxWidth = imgWidth - newWidth;
      let newPositionX = newMaxWidth * props.position / 100;
      if (newPositionX > newMaxWidth) {
        newPositionX = newMaxWidth;
      }
      setPreviewImageSize({ width: imgWidth, height: imgHeight });
      setAreaWidth(newWidth);
      setMaxWidth(newMaxWidth);
      setPositionX(newPositionX);
    }
  }, [imgWidth, imgHeight, props.position]);

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog as="div" className="relative z-10" onClose={() => setOpen(false)}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 overflow-y-auto">
          <div className="flex min-h-full items-center justify-center p-4 text-center ">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 "
              enterTo="opacity-100 translate-y-0 "
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 "
              leaveTo="opacity-0 translate-y-4 "
            >
              <Dialog.Panel className="group relative w-100 transform overflow-hidden rounded-lg bg-panterra-800 p-5 text-left shadow-xl transition-all">
                <div className="flex items-center justify-between pb-3 pt-5">
                  <Dialog.Title className="mx-1 text-2xl text-white">{t('timeLapseGeneratorPreviewHeader')}</Dialog.Title>
                  <CustomButton
                    action={() => setOpen(false)}
                    iconProps={{
                      icon: "XMarkIcon",
                    }}
                  />
                </div>
                <div className="group relative w-full">
                  <img
                    ref={imgRef}
                    className="w-full"
                    src={VideoUrlFormatter(props.projectName, props.cameraNumber, props.fileName, "preview")}
                    alt=""
                    onLoad={(e) => {
                      const target = e.target as HTMLImageElement;
                      const newWidth = computeWidth(target.offsetHeight);
                      const newMaxWidth = target.width - newWidth;
                      let newPositionX = newMaxWidth * props.position / 100;
                      if (newPositionX > newMaxWidth) {
                        newPositionX = newMaxWidth;
                      }
                      setPreviewImageSize({ width: target.offsetWidth, height: target.offsetHeight });
                      setAreaWidth(newWidth);
                      setMaxWidth(newMaxWidth);
                      setPositionX(newPositionX);
                    }}
                  />
                  <Draggable axis="x" bounds="parent" onDrag={handleDrag} position={{ x: positionX, y: 0 }} >
                    <div
                      className="absolute inset-0 border-4 border-red-500 cursor-x"
                      style={{ width: `${areaWidth}px`, cursor: "ew-resize" }}
                      onKeyDown={(e) => {
                        if (e.key === 'ArrowLeft') {
                          if (positionX > 0) {
                            setPositionX(positionX - 1);
                          }
                        } else if (e.key === 'ArrowRight') {
                          if (positionX < maxWidth) {
                            setPositionX(positionX + 1);
                          }
                        }
                        updatePosition(positionX);
                      }}
                      tabIndex={0}
                    ></div>
                  </Draggable>
                </div>
                <div className="mb-3 rounded-md bg-panterra-700 py-1 px-2 text-white text-blue-200">
                  {props.position}%
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
});
