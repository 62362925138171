import {PlayIcon} from "@heroicons/react/24/solid";
import {useEffect, useRef, useState} from "react";
import ReactPlayer from "react-player";
import ReactSlider from "react-slider";
import screenfull from "screenfull";
import {CustomButton} from "../CustomButton/CustomButton";

export type PlayerElementProps = {
  videoURL: string;
  previewURL?: string;
};

export const PlayerElement = (props: PlayerElementProps) => {
  const [isPlaying, setIsPlaying] = useState<boolean>(props.previewURL ? true : false);
  const [progress, setProgress] = useState<number>(0);
  const [loaded, setLoaded] = useState<number>(0);
  const [lightMode, setLightMode] = useState<boolean>(props.previewURL ? true : false);
  const [isFullScreen, setIsFullScreen] = useState<boolean>(false);

  const playerRef = useRef<ReactPlayer>(null);
  const wrapperRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (screenfull.isEnabled) {
      screenfull.on("change", () => setIsFullScreen(screenfull.isFullscreen));
    }
    return () => {
      if (screenfull.isEnabled) {
        screenfull.off("change", () => setIsFullScreen(screenfull.isFullscreen));
      }
    };
  });

  const formatSeconds = (seconds: number) => {
    if (isNaN(seconds)) return "00:00";
    const date = new Date(seconds * 1000);
    const hh = date.getUTCHours();
    const mm = date.getUTCMinutes();
    const ss = date.getUTCSeconds().toString().padStart(2, "0");

    if (hh) {
      return `${hh}:${mm.toString().padStart(2, "0")}:${ss}`;
    }

    return `${mm}:${ss}`;
  };

  const handleProgress = (changeState: any) => {
    if (changeState.played) setProgress(changeState.played);
    if (changeState.loaded) setLoaded(changeState.loaded);
  };

  const onProgressAfterChange = (newProgress: number[]) => {
    playerRef.current?.seekTo(newProgress[0], "fraction");
  };

  const onProgressChange = (newProgress: number[]) => {
    setProgress(newProgress[0]);
  };

  const onProgressClick = (newProgress: number) => {
    playerRef.current?.seekTo(newProgress, "fraction");
  };

  const toggleFullscreen = () => {
    if (screenfull.isEnabled && wrapperRef?.current) {
      screenfull.toggle(wrapperRef.current);
    }
  };

  return (
    <div ref={wrapperRef} className="relative bg-panterra-900">
      <ReactPlayer
        ref={playerRef}
        autoPlay={false}
        playing={isPlaying}
        muted={true}
        style={{
          // must be set for the preview picture
          aspectRatio: "3/2",
          // for mobile users only clicking the play button should play the video
          pointerEvents: lightMode ? "none" : "auto"
        }}
        width="100%"
        height="100%"
        playIcon={
          <div className="pointer-events-auto" onClick={() => setLightMode(false)} >
            <div className="h-10 w-10 px-0.5 rounded-full bg-primary-700 text-white">
              <PlayIcon className="h-10 w-10  bg-opacity-0 text-white" />
            </div>
          </div>
        }
        // prevent user from rightclicking the video
        onContextMenu={(e: MouseEvent) => e.preventDefault()}
        url={props.videoURL}
        light={props.previewURL ? props.previewURL : undefined}
        controls={false}
        progressInterval={10}
        onProgress={handleProgress}
        onEnded={() => setIsPlaying(false)}
      ></ReactPlayer>
      {/* show controls only after click on playIcon */}
      {!lightMode && (
        // asymetric fading transitions
        <div className="absolute inset-x-0 bottom-0 z-30 flex flex-row bg-panterra-800 opacity-0 transition-opacity delay-[2000ms] duration-500 hover:!opacity-100 hover:!delay-[1ms] group-hover:opacity-50 group-hover:!delay-[1ms]">
          <CustomButton
            action={() => setIsPlaying((current) => !current)}
            iconProps={{
              icon: isPlaying ? "PauseIcon" : "PlayIcon",
              iconClassName: "h-8 w-8",
            }}
            buttonPaddingClassName="ml-1"
            buttonHoverColorClassName="bg-panterra-800"
          ></CustomButton>
          <ReactSlider
            pearling={true}
            className="mx-1 flex h-8 flex-grow flex-row items-center"
            trackClassName="h-1 mx-3 rounded-full cursor-pointer customSlider-track"
            thumbClassName="bg-orange-600 text-black rounded-full cursor-grabbing customSlider-thumb"
            onChange={(e: number[]) => onProgressChange(e)}
            onAfterChange={(e: number[]) => onProgressAfterChange(e)}
            onSliderClick={(e: number) => onProgressClick(e)}
            orientation="horizontal"
            value={[progress, loaded]}
            min={0}
            max={1}
            step={0.001}
          />
          <div className="flex items-center text-base font-bold text-white">
            {playerRef.current
              ? `${formatSeconds(playerRef.current.getDuration() * (progress ? progress : 0))} - ${formatSeconds(
                  playerRef.current.getDuration(),
                )}`
              : "00:00 - 00:00"}
          </div>
          <CustomButton
            action={toggleFullscreen}
            iconProps={{
              icon: isFullScreen ? "ExitFullScreenSVG" : "FullScreenSVG",
              iconClassName: "h-6 w-6",
            }}
            buttonPaddingClassName="m-1 ml-2"
            buttonHoverColorClassName="bg-panterra-800"
          ></CustomButton>
        </div>
      )}
    </div>
  );
};
