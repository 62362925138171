import {Menu} from "./MainMenu";
import {Logo} from "./Logo";
import {Fragment, useEffect, useState} from "react";
import {Dialog, Transition} from "@headlessui/react";
import {useLocation} from "react-router-dom";
import {CustomButton} from "../shared/CustomButton/CustomButton";
import { useTranslation } from "react-i18next";

export function OffCanvas() {
  const [open, setOpen] = useState(false);
  const location = useLocation();
  const {t} = useTranslation();

  useEffect(() => {
    setOpen(false);
  }, [location]);

  return (
    <>
      <CustomButton
        tippyContent={t('tooltip:openNavigation')}
        action={() => {
          setOpen(true);
        }}
        iconProps={{icon: "Bars3Icon"}}
      />
      <Transition.Root show={open} as={Fragment}>
        <Dialog as="div" className={"relative z-30"} onClose={setOpen}>
          {/* Background pane */}
          <Transition.Child
            as={Fragment}
            enter="ease-in-out duration-500"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in-out duration-500"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className={"fixed inset-0 z-[90] bg-panterra-900 bg-opacity-75 transition-opacity"} />
          </Transition.Child>

          {/* OffCanvas Wrapper */}
          <div className={"fixed inset-0 z-[100] overflow-hidden"}>
            <div className={"pointer-events-none fixed inset-y-0 left-0 flex"}>
              {/* Panel */}
              <Transition.Child
                as={Fragment}
                enter="transform transition ease-in-out duration-500"
                enterFrom="-translate-x-full"
                enterTo="translate-x-0"
                leave="transform transition ease-in-out duration-500"
                leaveFrom="translate-x-0"
                leaveTo="-translate-x-full"
              >
                <Dialog.Panel className={"pointer-events-auto relative w-screen max-w-md"}>
                  {/* Content */}
                  <div className={"z-[100] flex h-full flex-col overflow-y-auto bg-panterra-900 py-12 shadow-2xl"}>
                    <div
                      className={"flex flex-row-reverse justify-between px-9 text-panterra-100 hover:text-panterra-50"}
                    >
                      {/* Close button */}
                      <CustomButton
                        action={() => {
                          setOpen(false);
                        }}
                        buttonPaddingClassName="p-1 m-1"
                        iconProps={{icon: "XMarkIcon"}}
                        tippyContent={t('tooltip:closeNavigation')}
                      />

                      {/* Logo */}
                      <Logo mayBeHidden={true} />
                    </div>

                    {/* Main menu*/}
                    <div className={"relative mt-12 px-6"}>
                      <Menu />
                    </div>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition.Root>
    </>
  );
}
