import React, {useState} from "react";
import {MediaType} from "../../../types/types";
import {CustomButton} from "../../shared/CustomButton/CustomButton";
import {CustomToolbar} from "../../shared/CustomToolbar/CustomToolbar";
import {downloadHelper} from "../../../services/downloadHelper";
import { useTranslation } from "react-i18next";

type StackToolbarProps = {
  type: MediaType;
  dailyStackUrl?: string;
  imageStackUrl?: string;
  imageZoomUrl?: string;
  mediaDownloadUrl: string;
  mediaDownloadName: string;
  addCurrentToCompareImages?: () => void;
  openDailyMerger?: () => void;
};

export const StackToolbar = (props: StackToolbarProps) => {
  const [downloadRunning, setDownloadRunning] = useState<boolean>(false);
  const {t} = useTranslation();

  const left: JSX.Element[] = [
    <CustomButton
      key={1}
      link={props.type !== "image" ? props.imageStackUrl : "#"}
      replace={true}
      iconProps={{
        icon: "PhotoIcon",
        iconClassName: "w-8 h-8",
      }}
      tippyContent={t('tooltip:toggleImageMode')}
      buttonSelected={props.type === "image" ? true : false}
    />,
    <CustomButton
      key={2}
      link={props.type !== "daily" ? props.dailyStackUrl : "#"}
      replace={true}
      iconProps={{
        icon: "VideoCameraIcon",
        iconClassName: "w-8 h-8",
      }}
      tippyContent={t('tooltip:toggleVideoMode')}
      buttonSelected={props.type === "daily" ? true : false}
    />,
  ];
  const right: JSX.Element[] = [
    props.type === "image" ? (
      <CustomButton
        key={3}
        action={props.addCurrentToCompareImages}
        iconProps={{
          icon: "PlusIcon",
          iconClassName: "w-8 h-8",
        }}
        tippyContent={t('tooltip:addCompareImage')}
      />
    ) : (
      <React.Fragment key={3} />
    ),
    props.type === "image" ? (
      <CustomButton
        key={4}
        link={props.imageZoomUrl}
        iconProps={{
          icon: "ArrowsPointingOutIcon",
          iconClassName: "w-8 h-8",
        }}
        tippyContent={t('tooltip:openZoom')}
      />
    ) : (
      <React.Fragment key={4} />
    ),
    props.type === "daily" ? (
      <CustomButton
        key={5}
        action={props.openDailyMerger}
        iconProps={{
          icon: "ScissorsIcon",
          iconClassName: "w-8 h-8",
        }}
        tippyContent={t('tooltip:openTimelapseGenerator')}
      />
    ) : (
      <React.Fragment key={5} />
    ),
    <CustomButton
      key={6}
      action={() => downloadHelper(props.mediaDownloadUrl, props.mediaDownloadName, setDownloadRunning)}
      iconProps={{
        icon: downloadRunning ? "HourGlassSVG" : "ArrowDownTrayIcon",
        iconClassName: downloadRunning ? "w-6 h-6" : "w-8 h-8",
      }}
      download={props.mediaDownloadName}
      buttonPaddingClassName={downloadRunning ? "p-3 mx-1" : undefined}
      buttonDisabled={downloadRunning}
      tippyContent={props.type === "image" ? t('tooltip:downloadImage') : t('tooltip:downloadVideo')}
    />,
  ];
  return <CustomToolbar left={left} right={right}></CustomToolbar>;
};
