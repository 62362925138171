/* eslint-disable react-hooks/exhaustive-deps */
import {useNavigate, useParams} from "react-router";
import {TileSourceOptions} from "openseadragon";
import {useContext, useEffect, useMemo, useRef, useState} from "react";
import apiFetch from "../../../services/dataAccess";
import {ProjectsContext} from "../../../context/ProjectsProvider";
import {ConciseRecording, ProjectAndCamParams} from "../../../types/types";
import {ImageUrlFormatter} from "../../../services/urlFormatter";
import {CenterPoint, ITileViewer, TileViewer} from "./TileViewer";
import {Pageloader} from "../../shared/Pageloader/Pageloader";
import {DateTimeFormatter} from "../../../services/stringFormatter";
import {CompareImagesContext} from "../../../context/CompareImagesProvider";
import screenfull from "screenfull";
import {ProjectBrandingPTLogo} from "../../shared/ProjectBrandingPTLogo/ProjectBrandingPTLogo";
import {ProjectBrandingClientLogo} from "../../shared/ProjectBrandingClientLogo/ProjectBrandingClientLogo";
import ReactSlider from "react-slider";
import classNames from "classnames";
import {ControlBar} from "./ControlBar";
import {CustomButton} from "../../shared/CustomButton/CustomButton";
import { BlankPageWithBackButton} from "../../shared/BlankPage/BlankPageWithBackButton";
import { useTranslation } from "react-i18next";

export const DualFade = () => {
  const navigate = useNavigate();
  const {projectName: currentProject, cameraNumber} = useParams<ProjectAndCamParams>() as ProjectAndCamParams;
  const {state: projects} = useContext(ProjectsContext);
  const {t} = useTranslation();

  const [recordings, setRecordings] = useState<ConciseRecording[]>([]);
  const [tileSources, setTileSources] = useState<Partial<TileSourceOptions>[]>([]);
  const [fader, setFader] = useState<number>(100);
  const {compareImages} = useContext(CompareImagesContext);
  const [isFullScreen, setIsFullScreen] = useState<boolean>(false);

  const project = projects[currentProject];
  const camera = project.cameras[cameraNumber];
  const tileViewerIds = [0, 1];
  const tileViewers = tileViewerIds.map(() => {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const tileViewer = useRef<ITileViewer>(null);
    return tileViewer;
  });

  const wrapper = useRef<HTMLDivElement>(null);

  const generateTileSource = (datetime: string): TileSourceOptions & {type: string; tilesUrl: string} => {
    return {
      type: "zoomifytileservice",
      width: camera.pixelWidth,
      height: camera.pixelHeight,
      tilesUrl: ImageUrlFormatter(currentProject, cameraNumber, datetime, "zoom"),
    };
  };

  useEffect(() => {
    if (screenfull.isEnabled) {
      screenfull.on("change", () => setIsFullScreen(screenfull.isFullscreen));
    }
    return () => {
      if (screenfull.isEnabled) {
        screenfull.off("change", () => setIsFullScreen(screenfull.isFullscreen));
      }
    };
  });

  useEffect(() => {
    apiFetch(`cameras/${camera.id}/recordings/concise`)
      .then((response) => response.json())
      .then((data) => {
        setRecordings(data);
      });
  }, []);

  useEffect(() => {
    if (!recordings.length) return;
    const _tileSources: TileSourceOptions[] = compareImages.map((index) =>
      generateTileSource(recordings[index].timestamp),
    );
    setTileSources(_tileSources);
  }, [recordings]);

  const onSliderChange = (sliderValue: number) => {
    setFader(sliderValue);
    tileViewers[1].current?.setOpacity(sliderValue / 100);
  };

  const handleNavigate = (zoomLevel: number, coordinates: CenterPoint, id: number) => {
    const otherId = id === 0 ? 1 : 0;
    tileViewers[otherId].current?.navigateTo(zoomLevel, coordinates);
  };

  const toggleFullscreen = () => {
    if (screenfull.isEnabled && wrapper.current) {
      screenfull.request(wrapper.current);
    }
  };

  // tell react to rerender the tileviewers only if tilesources change, which prevents stuttering during slider change
  const tileViewerElements = useMemo(() => {
    return tileViewerIds.map((id) => (
      <div key={id} className={classNames("absolute inset-0", {"z-0": id === 0, "z-10": !(id === 0)})}>
        <TileViewer
          key={id}
          showNavigator={false}
          ref={tileViewers[id]}
          id={id}
          tileSource={tileSources[id]}
          handleNav={handleNavigate}
        ></TileViewer>
      </div>
    ));
  }, [tileSources]);

  // Do not render if no data is available. Show message if for some reason there is no valid image selection
  if (tileSources.length === 0) {
    return (
      <BlankPageWithBackButton >
        {compareImages.length !== 2 ? (
              <div>
                {t('imageSelectionNotValid')}
              </div>
            ) : (
              <Pageloader />
            )}
      </BlankPageWithBackButton>
    )
  }

  return (
    <div className="container mx-auto flex h-full flex-col pt-8 pb-8">
      <div ref={wrapper} className="flex flex-grow flex-row items-stretch bg-panterra-900">
        {/*Fader slider*/}
        <div className="flex flex-col items-center px-2">
          <div className="whitespace-nowrap px-2">{DateTimeFormatter(recordings[compareImages[1]].timestamp)}</div>
          <div className="flex-grow py-5">
            <ReactSlider
              className="flex h-full w-8 flex-col items-center"
              trackClassName="w-1 rounded-full cursor-pointer stackCustomSlider-track" //coloring requirescustomSilder-track to be last item in className
              thumbClassName="bg-panterra-600 cursor-grabbing text-white whitespace-nowrap px-2 rounded-md"
              onChange={(e) => onSliderChange(e as number)}
              orientation="vertical"
              value={fader}
              invert={true}
              min={0}
              max={100}
              step={1}
              renderThumb={(props, state) => <div {...props}>{fader}</div>}
            />
          </div>
          <div className="whitespace-nowrap px-2">{DateTimeFormatter(recordings[compareImages[0]].timestamp)}</div>
        </div>

        <div className="relative h-full w-full">
          {/* Two tileviewers on top of each other*/}
          {tileViewerElements}
          {/* Controls and Overlays */}
          {project.showPTLogoInTF && <ProjectBrandingPTLogo className={"!bottom-10 !left-5"}></ProjectBrandingPTLogo>}
          {project.showClientLogoInTF && (
            <ProjectBrandingClientLogo
              projectLogo={project.projectLogo}
              className={"absolute top-2 right-12 z-20 h-8"}
            ></ProjectBrandingClientLogo>
          )}
          <ControlBar
            isFullScreen={isFullScreen}
            goHome={() => tileViewers.forEach((tileViewer) => tileViewer.current?.goHome())}
            zoomIn={() => tileViewers.forEach((tileViewer) => tileViewer.current?.zoomIn())}
            zoomOut={() => tileViewers.forEach((tileViewer) => tileViewer.current?.zoomOut())}
            toggleFullScreen={toggleFullscreen}
          ></ControlBar>
          <CustomButton
            action={() => navigate(-1)}
            buttonPositioning="absolute top-0 right-0 z-20"
            buttonPaddingClassName="p-1 m-1"
            buttonBG="bg-panterra-800"
            iconProps={{
              icon: "XMarkIcon",
            }}
            tippyContent={t('tooltip:navigateBack')}
          ></CustomButton>
        </div>
      </div>
    </div>
  );
};
