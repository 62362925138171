import fileDownload from "js-file-download";

export const downloadHelper = (downloadUrl: string, fileName: string, setDownloadButtonDisabled?: Function) => {
  setDownloadButtonDisabled && setDownloadButtonDisabled(true);
  fetch(downloadUrl, {
    method: "GET",
    //token goes here
  })
    .then((response) => {
      return response.blob();
    })
    .then((blob) => {
      fileDownload(blob, fileName);
      setDownloadButtonDisabled && setTimeout(() => setDownloadButtonDisabled(false), 500);
    });
};
