import {Transition} from "@headlessui/react";
import {DateTimeFormatter} from "../../../services/stringFormatter";
import {ImageUrlFormatter} from "../../../services/urlFormatter";
import {ConciseRecording} from "../../../types/types";
import {CustomButton} from "../../shared/CustomButton/CustomButton";
import {ImageFooter} from "../../shared/ImageFooter/ImageFooter";
import { useTranslation } from "react-i18next";

type SelectedImagesProps = {
  recordings: ConciseRecording[];
  compareImages: number[];
  project: string;
  cameraNumber: string;
  showCompareImages: boolean;
  clearCompareImages: () => void;
  removeCompareImage: (index: number) => void;
};

export const SelectedImages = (props: SelectedImagesProps) => {
  const dualZoomURL = `/projects/${props.project}/${props.cameraNumber}/dualzoom`;
  const dualFadeURL = `/projects/${props.project}/${props.cameraNumber}/dualfade`;
  const {t} = useTranslation();

  return (
    <Transition
      className="flex flex-col justify-center"
      show={props.showCompareImages}
      enter="transform transition duration-500"
      enterFrom="translate-x-full"
      leave="transform transition duration-150"
      leaveTo="translate-x-full"
    >
      <div className="my-3 flex rounded-sm bg-panterra-800 py-1 pl-1">
        <CustomButton
          link={props.compareImages.length === 2 ? dualZoomURL : "#"}
          buttonPaddingClassName="p-1 m-1"
          tippyContent={t('tooltip:openSideBySideZoomer')}
          iconProps={{
            icon: "ArrowsRightLeftIcon",
            iconClassName: "w-8 h-8",
          }}
        ></CustomButton>
        <CustomButton
          link={props.compareImages.length === 2 ? dualFadeURL : "#"}
          buttonPaddingClassName="p-1 m-1"
          tippyContent={t('tooltip:openCrossFadeZoomer')}
          iconProps={{
            icon: "AdjustmentsVerticalIcon",
            iconClassName: "w-8 h-8",
          }}
        ></CustomButton>
        <div className="flex-grow" />
        <CustomButton
          action={() => props.clearCompareImages()}
          buttonPaddingClassName="p-1 m-1"
          tippyContent={t('tooltip:clearCompareImages')}
          iconProps={{
            icon: "TrashIcon",
            iconClassName: "w-8 h-8",
          }}
        ></CustomButton>
      </div>
      {props.compareImages.map((index) => (
        <div key={index} className="relative my-3 md:max-w-[160px] lg:max-w-[200px] xl:max-w-[260px] 2xl:max-w-[320px] 3xl:max-w-[400px]">
          <img
            className="rounded-sm"
            src={ImageUrlFormatter(props.project, props.cameraNumber, props.recordings[index].timestamp, "small")}
            alt=""
          ></img>
          <ImageFooter className="justify-end">
            <span>{DateTimeFormatter(props.recordings[index].timestamp)}</span>
          </ImageFooter>
          <CustomButton
            action={() => props.removeCompareImage(index)}
            buttonPositioning="absolute top-0 right-0"
            tippyContent={t('tooltip:removeCompareImage')}
            buttonPaddingClassName="p-1 m-1"
            buttonBG="bg-panterra-800"
            iconProps={{
              icon: "TrashIcon",
            }}
          ></CustomButton>
        </div>
      ))}
    </Transition>
  );
};
