/* eslint-disable react-hooks/exhaustive-deps */
import {useNavigate, useParams} from "react-router";
import {useContext, useEffect, useRef, useState} from "react";
import {ProjectsContext} from "../../../context/ProjectsProvider";
import {ITileViewer, TileViewer} from "./TileViewer";
import {CustomButton} from "../../shared/CustomButton/CustomButton";
import screenfull from "screenfull";
import {ProjectBrandingPTLogo} from "../../shared/ProjectBrandingPTLogo/ProjectBrandingPTLogo";
import {ControlBar} from "./ControlBar";
import {OSDLegend} from "./OSDLegend";
import Tippy from "@tippyjs/react";
import {QuestionMarkCircleIcon} from "@heroicons/react/24/solid";
import { useTranslation } from "react-i18next";

type ProjectParam = {
  projectName : string;
  imageWidgetId: string;
}

export const UnTiledSingleZoom = () => {
  const navigate = useNavigate();
  const {projectName, imageWidgetId} = useParams() as ProjectParam;
  const {state: projects} = useContext(ProjectsContext);
  const [isFullScreen, setIsFullScreen] = useState<boolean>(false);
  const {t} = useTranslation();

  const project = projects[projectName];
  const imageWidget = project.imageWidgets[imageWidgetId]
  const tileSource = {
    type: 'image',
    url: `${process.env.REACT_APP_API_ENTRYPOINT}assets/image_widgets/${imageWidget.image}`,
    // buildPyramid: false
    // crossOriginPolicy: 'Anonymous',
    // ajaxWithCredentials: false
    // If Cors is bugging out set pyramid to false or try to get the ajax stuff done somehow
  }

  const tileViewer = useRef<ITileViewer>(null);
  const wrapper = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (screenfull.isEnabled) {
      screenfull.on("change", () => setIsFullScreen(screenfull.isFullscreen));
    }
    return () => {
      if (screenfull.isEnabled) {
        screenfull.off("change", () => setIsFullScreen(screenfull.isFullscreen));
      }
    };
  });

  const toggleFullscreen = () => {
    if (screenfull.isEnabled && wrapper.current) {
      screenfull.toggle(wrapper.current);
    }
  };

  return (
    <div className="container mx-auto flex h-full flex-col pt-8 pb-8">
      {/* One Tileviewer */}
      <div ref={wrapper} className={"relative flex-grow bg-panterra-900"}>
        <TileViewer showNavigator={true} ref={tileViewer} tileSource={tileSource} id={1}></TileViewer>

        {/* Controls and Overlays */}
        {project.showPTLogoInTF && <ProjectBrandingPTLogo className={"!bottom-12 !left-5"}></ProjectBrandingPTLogo>}
        <ControlBar
          isFullScreen={isFullScreen}
          goHome={() => tileViewer.current?.goHome()}
          zoomIn={() => tileViewer.current?.zoomIn()}
          zoomOut={() => tileViewer.current?.zoomOut()}
          toggleFullScreen={toggleFullscreen}
        ></ControlBar>
        <Tippy content={<OSDLegend />} placement={"bottom-end"}>
          <div className="absolute top-0 right-12 m-1 rounded-md bg-panterra-800 p-1 hover:bg-panterra-700">
            <QuestionMarkCircleIcon className="h-7 w-7" />
          </div>
        </Tippy>
        <CustomButton
          action={() => navigate(-1)}
          buttonPositioning="absolute top-0 right-0"
          buttonPaddingClassName="p-1 m-1"
          buttonBG="bg-panterra-800"
          tippyContent={t('tooltip:navigateBack')}
          iconProps={{
            icon: "XMarkIcon",
          }}
        ></CustomButton>
      </div>
    </div>
  );
};
