/* eslint-disable react-hooks/exhaustive-deps */
import {useContext, useEffect, useState} from "react";
import {useNavigate} from "react-router";
import apiFetch from "../../../services/dataAccess";
import {
  DateFormatter,
  DateTimeFormatter,
  ImageDownloadStringFormatter,
  VideoDownloadStringFormatter,
} from "../../../services/stringFormatter";
import {ImageUrlFormatter, VideoUrlFormatter} from "../../../services/urlFormatter";
import {Camera, ConciseRecording, Daily, MediaType, Project} from "../../../types/types";
import {UserConfigurationContext} from "../../../context/UserConfigurationProvider";
import classNames from "classnames";
import {PlayerElement} from "../../shared/PlayerElement/PlayerElement";
import {ProjectBrandingPTLogo} from "../../shared/ProjectBrandingPTLogo/ProjectBrandingPTLogo";
import {ProjectBrandingClientLogo} from "../../shared/ProjectBrandingClientLogo/ProjectBrandingClientLogo";
import {LatestImageToolbar} from "./LatestImageToolbar";
import {ImageFooter} from "../../shared/ImageFooter/ImageFooter";
import { Pageloader } from "../../shared/Pageloader/Pageloader";
import { useTranslation } from "react-i18next";

export const LatestImage = ({project, camera}: {project: Project; camera: Camera}) => {
  const navigate = useNavigate();
  const {t} = useTranslation();

  const [latestImageDateTime, setLatestImageDateTime] = useState<string | null>(null);
  const [latestDailyDate, setLatestDailyDate] = useState<string>("");
  const [latestDailyFileName, setLatestDailyFileName] = useState<string>("");
  const [mediaType, setMediaType] = useState<MediaType>("image");
  const {isPtAdmin} = useContext(UserConfigurationContext);

  async function fetchLatestRecording() {
    apiFetch(`/cameras/${camera.id}/recordings/concise?limit=1`)
      .then((r) => r.json())
      .then((response: ConciseRecording[]) => {
        if (!response.length) {
          setLatestImageDateTime("");
          return;
        }
        setLatestImageDateTime(response[0].timestamp);
      });

    apiFetch(`/cameras/${camera.id}/dailys/concise?limit=1`)
      .then((r) => r.json())
      .then((response: Daily[]) => {
        if (!response.length) return;
        setLatestDailyFileName(response[0].fileName);
        setLatestDailyDate(response[0].timestamp);
      });
  }

  useEffect(() => {
    fetchLatestRecording();

    const interval = setInterval(() => {
      fetchLatestRecording();
    }, 60000);

    return () => clearInterval(interval);
  }, []);

  if (latestImageDateTime === null) {
    return <></>;
  }
  if (latestImageDateTime === "") {
    return (
      <div
        className="w-full border border-red-500">
        <div
          style={{
            aspectRatio: "3/2"
          }}
          className="flex flex-col justify-center">
          <Pageloader/>
        </div>
        <ImageFooter className="h-8">
          {project.name} - {camera.name} {t('cameraNoImagesYet')}
        </ImageFooter>
      </div>
    );
  }

  const imageStackUrl = `/projects/${project.project}/${camera.cameraNumber}`;
  const imageZoomUrl = `/projects/${project.project}/${camera.cameraNumber}/zoom`;
  const dailyStackUrl = `/projects/${project.project}/${camera.cameraNumber}/dailys`;
  const imageUrl = ImageUrlFormatter(project.project, camera.cameraNumber, latestImageDateTime, "small");
  const dailyUrl = VideoUrlFormatter(project.project, camera.cameraNumber, latestDailyFileName, "daily");
  const dailyPreviewUrl = VideoUrlFormatter(project.project, camera.cameraNumber, latestDailyFileName, "preview");

  let footerDate: string;
  let tileContent: JSX.Element;
  let mediaStackUrl: string;
  let mediaDownloadUrl: string;
  let mediaDownloadName: string;

  switch (mediaType) {
    case "daily": {
      footerDate = DateFormatter(latestDailyDate);
      tileContent = <PlayerElement videoURL={dailyUrl} previewURL={dailyPreviewUrl}></PlayerElement>;
      mediaStackUrl = dailyStackUrl;
      mediaDownloadUrl = dailyUrl;
      mediaDownloadName = VideoDownloadStringFormatter(project.name, camera.name, latestDailyDate);
      break;
    }
    case "image": {
      footerDate = DateTimeFormatter(latestImageDateTime);
      tileContent = (
        <img
          className={"latest-image"}
          onDoubleClick={() => navigate(imageStackUrl)}
          src={imageUrl}
          alt={t('notAvailableMessage')}
        />
      );
      mediaStackUrl = imageStackUrl;
      mediaDownloadUrl = ImageUrlFormatter(
        project.project,
        camera.cameraNumber,
        latestImageDateTime,
        isPtAdmin || project.allowImageSrcDownload ? "full" : "medium",
      );
      mediaDownloadName = ImageDownloadStringFormatter(project.name, camera.name, latestImageDateTime);
      break;
    }
  }

  return (
    <div className={classNames("group relative", {"border border-primary-700": mediaType === "daily"})}>
      <LatestImageToolbar
        type={mediaType}
        setType={setMediaType}
        mediaStackUrl={mediaStackUrl}
        imageZoomUrl={imageZoomUrl}
        hasDaily={latestDailyFileName !== ""}
        mediaDownloadUrl={mediaDownloadUrl}
        mediaDownloadName={mediaDownloadName}
      ></LatestImageToolbar>
      {project.showPTLogoInTF && <ProjectBrandingPTLogo className={"!bottom-12"}></ProjectBrandingPTLogo>}
      {project.showClientLogoInTF && (
        <ProjectBrandingClientLogo projectLogo={project.projectLogo}></ProjectBrandingClientLogo>
      )}
      <div className="w-full">
        {tileContent}
        <ImageFooter>
          <span
            className="whitespace-nowrap overflow-hidden overflow-ellipsis">
            {project.name} - {camera.name}
          </span>
          <span
          className={classNames("whitespace-nowrap", {"min-w-[82px]": mediaType === "daily", "min-w-[145px]" : mediaType==="image"})}>
            {footerDate}
          </span>
        </ImageFooter>
      </div>
    </div>
  );
};
