import {ArchiveBoxIcon, ChartPieIcon, HomeIcon, BuildingLibraryIcon, UserGroupIcon} from "@heroicons/react/24/solid";
import {MenuItem} from "../shared/Menu/MenuItem";
import {SubMenuItem} from "../shared/Menu/SubMenuItem";
import {ReactElement, useContext} from "react";
import {ProjectsContext} from "../../context/ProjectsProvider";
import {CameraIcon} from "@heroicons/react/24/outline";
import {UserConfigurationContext} from "../../context/UserConfigurationProvider";
import {useTranslation} from 'react-i18next'

export const Menu = () => {
  const {t} = useTranslation()
  const {state: projectState} = useContext(ProjectsContext);
  const {isPtAdmin, hasProjects} = useContext(UserConfigurationContext);

  const HomeMenuItem = (
    <MenuItem icon={<HomeIcon className={"h-6 w-6"}></HomeIcon>} label={t('landingPage')} link={"/"}></MenuItem>
  );

  const projectMenuItems = Object.values(projectState).map((project) => {
    return (
      <MenuItem
        icon={<ArchiveBoxIcon className={"h-6 w-6"}></ArchiveBoxIcon>}
        label={project.name}
        link={`/projects/${project.project}`}
        key={project.id}
      >
        {Object.values(project.cameras).map((camera) => (
          <SubMenuItem
            label={camera.name}
            link={`/projects/${project.project}/${camera.cameraNumber}`}
            key={camera.id}
            icon={<CameraIcon className={"h-6 w-6"}></CameraIcon>}
          />
        ))}
      </MenuItem>
    );
  });

  const KeyCloakItem = (
    <MenuItem
      externalLink={true}
      link={`${process.env.REACT_APP_KEYCLOAK_AUTH_URL}admin`}
      icon={<UserGroupIcon className={"h-6 w-6"}></UserGroupIcon>}
      label={t('admin:usersAndGroups')}
    ></MenuItem>
  );

  const AdminItem = (
    <MenuItem
      externalLink={true}
      link={`${process.env.REACT_APP_API_ENTRYPOINT}admin`}
      icon={<BuildingLibraryIcon className={"h-6 w-6"}></BuildingLibraryIcon>}
      label={t('admin:projectConfiguration')}
    ></MenuItem>
  );

  const MonitoringItem = (
    <MenuItem
      externalLink={true}
      link={`${process.env.REACT_APP_GRAFANA_URL}`}
      icon={<ChartPieIcon className={"h-6 w-6"}></ChartPieIcon>}
      label={t('admin:hetznerMonitoring')}
    ></MenuItem>
  );

  let renderMenu: ReactElement;

  // new User
  if (hasProjects === false) {
    renderMenu = HomeMenuItem;
  }

  // Admin
  else if (hasProjects && isPtAdmin) {
    renderMenu = (
      <>
        {HomeMenuItem}
        {KeyCloakItem}
        {AdminItem}
        {MonitoringItem}
        {projectMenuItems}
      </>
    );
  }
  // Client
  else {
    renderMenu = (
      <>
        {HomeMenuItem}
        {projectMenuItems}
      </>
    );
  }

  return <div className={"space-y-2"}>{renderMenu}</div>;
};
