/* eslint-disable react-hooks/exhaustive-deps */
import { forwardRef, Fragment, useContext, useImperativeHandle, useRef, useState } from "react";
import { Dialog, Switch, Tab, Transition } from "@headlessui/react";
import { CustomButton } from "../../shared/CustomButton/CustomButton";
import { CustomListbox } from "../../shared/CustomListbox";
import { Daily, ProjectAndCamParams } from "../../../types/types";
import { useParams } from "react-router-dom";
import { addDays, differenceInDays, subDays } from "date-fns";
import { CalendarDateToDailyDateStringFormatter, DateFromDateFormatter } from "../../../services/stringFormatter";
import { getNearestTimestampIndex } from "../../../services/getNearestTimestamp";
import { DateRange, DayPicker } from "react-day-picker";
import { ProjectsContext } from "../../../context/ProjectsProvider";
import { DailyPlayerModal } from "./DailyPlayerModal";
import { IPortraitSelectorModal, PortraitSelectorModal } from "./PortraitSelectorModal";
import en from "date-fns/locale/en-GB";
import { useWindowWidth } from '@react-hook/window-size'
import { useTranslation } from "react-i18next";
import { lngs } from "../../layout/LanguageMenu";
import { UserConfigurationContext } from "../../../context/UserConfigurationProvider";
import apiFetch from "../../../services/dataAccess";
import { Toaster, toast } from "react-hot-toast";
import { Icon } from "../../shared/Icon/Icon";

// This feature is using two step procedure:
// 1) Request demon.php to merge videos given in the format of requestMergedDailysDownloadKey() fetch body
// 2) Get 'video_name' from the result and send it as query parameter to download_daily_video.php

// Known open issues:
// - Invalid dates where for some reason no daily was created are NOT hidden right now.
// - User is in prinicple not limited to any amount of days if he is smart enough to understand the code
// - In principle there should be a method DailyDateStringToCalendarDateFormatter, which is used
//  whereever a Date is injected to the Calendar. Luckily the 12:00:01 timestamp makes the days differences correct almost everywhere
//  (maybe not in australia?) and we ran out of time creating all the custom logic required. This is definitely something to look at
//  when there is more time.

export interface IDailyMergerModal {
  setModalOpen: (modalOpen: boolean) => void;
}

type DailyMergerModalProps = {
  dailys: Daily[];
};

const processSelectedDailysURL = "https://content.panterra.de/bin/DailysServer/demon.php";
const downloadMergedDailysBaseURL = "https://content.panterra.de/download_dailys_video.php";
// this one got hacked in afterwards because download_dailys_video.php does not support partial content (required for video seeking)
const previewMergedDailysBaseURL = "https://content.panterra.de/preview_dailys_video.php";

const maxDays = 31;

export const DailyMergerModal = forwardRef((props: DailyMergerModalProps, ref) => {
  const screenWidth = useWindowWidth()
  const [open, setOpen] = useState(false);
  const [buildInProgress, setBuildInProgress] = useState(false);
  const { state: projects } = useContext(ProjectsContext);
  const { projectName, cameraNumber } = useParams() as ProjectAndCamParams;
  const project = projects[projectName];
  const camera = project.cameras[cameraNumber];
  const { t, i18n } = useTranslation();
  // calendar props
  const minDate = new Date(props.dailys[props.dailys.length - 1].timestamp);
  const maxDate = new Date(props.dailys[0].timestamp);
  const [startDate, setStartDate] = useState(subDays(maxDate, maxDays - 1));
  const [endDate, setEndDate] = useState(maxDate);
  const [range, setRange] = useState<DateRange | undefined>();
  const validRange = range?.to && range?.from && Math.abs(differenceInDays(range.to, range.from)) <= 31;

  // url parameters
  const [mergedDailysDownloadKey, setMergedDailysDownloadKey] = useState<string | null>(null);
  const downloadMergedDailysURL = `${downloadMergedDailysBaseURL}?cp=${projectName}&key=${mergedDailysDownloadKey}`;
  const previewMergedDailysURL = `${previewMergedDailysBaseURL}?cp=${projectName}&key=${mergedDailysDownloadKey}`;
  const paddedCameraNumber = cameraNumber.padStart(2, "0");

  const { isPtAdmin, hasPreviewAccess } = useContext(UserConfigurationContext);
  const timeLapseLength = 240;
  const cameraNumberInt = parseInt(cameraNumber, 10);
  const timeLapseDefaultUrl = `https://content.panterra.de/projects/${projectName}/cam_${paddedCameraNumber}/sv/${projectName}_${cameraNumberInt}_sv_${timeLapseLength}.mp4`;
  const [timeLapseUrl, setTimeLapseUrl] = useState<string>(timeLapseDefaultUrl);

  // "mb-3 rounded-md bg-panterra-700 py-1 px-2 text-white text-blue-200"
  const tabClasses = "inline-block px-2 py-1 text-lg rounded-lg text-panterra-400 text-panterra-300 hover:text-panterra-50 hover:bg-panterra-500 bg-panterra-600 mx-2";
  const selectedTabClasses = "inline-block px-2 py-1 text-lg rounded-lg text-panterra-50 bg-panterra-500 mx-2";

  // ref to open player
  const dailyPlayer = useRef<IDailyMergerModal>(null);
  const timeLapsePlayer = useRef<IDailyMergerModal>(null);

  const portraitSelector = useRef<IPortraitSelectorModal>(null);

  // forward ref to open the modal from stack
  useImperativeHandle(ref, () => ({
    setModalOpen(modalOpen: boolean) {
      setOpen(modalOpen);
    },
  }));

  // find best fitting timestamp, slice dailys and revert.
  // cut the Timezone Offset from the Calendar date to match DB timestamp format
  const generateDailysArray = (startDate: Date, endDate: Date) => {
    const firstIndex = getNearestTimestampIndex(props.dailys, CalendarDateToDailyDateStringFormatter(startDate));
    const lastIndex = getNearestTimestampIndex(props.dailys, CalendarDateToDailyDateStringFormatter(endDate));
    const bla = props.dailys
      .slice(lastIndex, firstIndex + 1)
      .map((element) => element.fileName)
      .reverse();
    return bla;
  };

  const requestMergedDailysDownloadKey = async () => {
    // allowing gracefully 31 days if range corners dont exist and the best fit jumps outside
    if (!validRange) return;
    setBuildInProgress(true);
    setMergedDailysDownloadKey(null);
    const dailysArray = generateDailysArray(range.from as Date, range.to as Date);
    // construct body for hetzner script
    const body = {
      cp: projectName,
      //remove leading zeros if any
      cn: parseInt(cameraNumber, 10).toString(),
      sv: dailysArray.map((dailyFileName) => {
        return { p: dailyFileName };
      }),
    };

    // call hetzner script to merge videos
    fetch(processSelectedDailysURL, {
      method: "POST",
      body: JSON.stringify(body),
    })
      .then((res) => res.json())
      .then((object) => {
        setBuildInProgress(false);
        if (!object[0]["video_name"]) return;
        setMergedDailysDownloadKey(object[0]["video_name"].replaceAll('"', ""));
      });
  };

  const convertDate = (date: Date): string => {
    return date.toISOString().split("T")[0];
  }

  const requestTimeLapseVideo = async () => {
    if (!hasPreviewAccess) {
      toast.error(t('noPreviewAccess'));
      return;
    }

    const queryParams = new URLSearchParams();
    if (range?.from) {
      queryParams.append('start_date', convertDate(range.from));
    }
    if (range?.to) {
      queryParams.append('end_date', convertDate(range.to));
    }
    if (enabled) {
      queryParams.append('num_images', '480');
    } else {
      queryParams.append('num_images', '240');
    }
    if (portraitEnabled) {
      queryParams.append('is_portrait', '1');
    }
    queryParams.append('portrait_x_offset_percent', portraitXOffsetPercent.toString());
    queryParams.append('resolution', selectedResolution.name);
    queryParams.append('encoding', selectedEncoding.name);
    queryParams.append('preset', selectedResolution.name);
    queryParams.append('preset', selectedPreset.name);
    queryParams.append('crf', selectedCrf.name);
    queryParams.append('framerate', selectedFramerate.name);
    if (selectedBlending.value > 0) {
      queryParams.append('blending', selectedBlending.name);
    }
    const queryString = queryParams.toString();

    setBuildInProgress(true);
    setTimeLapseUrl(timeLapseDefaultUrl);
    apiFetch(`/timelapse/${camera.id}?${queryString}`, {
      method: "PUT",
      body: JSON.stringify({}),
    })
      .then((res) => res.json())
      .then((object) => {
        toast(t('timeLapseGeneratorStarted'));
        getVideoStatus(object.timelapseFilename, object.numberOfFrames);
        setTimeLapseUrl(`${downloadMergedDailysBaseURL}?cp=${projectName}&key=${object.timelapseFilename}`);
      })
      .catch((error) => {
        toast.error(error.message)
        setBuildInProgress(false);
      });
  }

  const getVideoStatus = async (timelapseFilename: string, numberOfFrames: number) => {
    apiFetch(`/timelapse-status/${timelapseFilename}`, {
      method: "GET",
    })
      .then((res) => res.json())
      .then((object) => {
        if (object.status === "end") {
          setBuildInProgress(false);
          toast.success(t('timeLapseGeneratorFinished'));
        } else {
          setTimeout(() => {
            getVideoStatus(timelapseFilename, numberOfFrames);
          }, 2000);
        }
        const frame = object.frame ? object.frame : 0;
        const percent = Math.floor(frame / numberOfFrames * 100);
        toast(t('progress') + ` ${percent} %`);
      })
      .catch((error) => { setBuildInProgress(false); });
  }

  // clear all calendar props
  const clearSelection = () => {
    setMergedDailysDownloadKey(null);
    setRange(undefined);
    setStartDate(minDate);
    setEndDate(maxDate);
  };
  const selectLast7Days = () => {
    setMergedDailysDownloadKey(null);
    setRange({ from: subDays(maxDate, 6), to: maxDate });
    setStartDate(minDate);
    setEndDate(maxDate);
  };
  const selectLast30Days = () => {
    setMergedDailysDownloadKey(null);
    setRange({ from: subDays(maxDate, 30), to: maxDate });
    setStartDate(minDate);
    setEndDate(maxDate);
  };
  const selectCurrentYear = () => {
    setMergedDailysDownloadKey(null);
    setRange({ from: new Date(new Date().getFullYear(), 0, 1), to: maxDate });
    setStartDate(minDate);
    setEndDate(maxDate);
  }
  const selectSinceBeginning = () => {
    setMergedDailysDownloadKey(null);
    setRange({ from: minDate, to: maxDate });
    setStartDate(minDate);
    setEndDate(maxDate);
  }

  // Disable invalid selections if range is not fully defined. Somehow this feature is not implemented
  // and the calendar allows any date to be picked after the first click.
  const onDayClick = (value: any) => {
    setMergedDailysDownloadKey(null);
    if (range === undefined || !range.to) {
      if (subDays(value, maxDays - 1) > minDate) setStartDate(subDays(value, maxDays - 1));
      if (addDays(value, maxDays - 1) < maxDate) setEndDate(addDays(value, maxDays - 1));
    } else {
      setStartDate(minDate);
      setEndDate(maxDate);
    }
  };

  // Footer
  let Footer = () => <span>{t('selectTimeLapseGeneratorFirstDate')}</span>;
  if (range?.from) {
    if (!range.to) {
      Footer = () => (
        <span>{t('selectTimeLapseGeneratorSecondDate', [DateFromDateFormatter(range.from as Date)])}</span>
      );
    } else if (range.to) {
      Footer = () => (
        <span>{t('selectTimeLapseGeneratorDatesComplete', [DateFromDateFormatter(range.from as Date), DateFromDateFormatter(range.to as Date,)])}</span>
      );
    }
  }

  const [selectedIndex, setSelectedIndex] = useState(0)
  const [enabled, setEnabled] = useState(false)
  const [portraitEnabled, setPortraitEnabled] = useState(false)
  const [portraitXOffsetPercent, setPortraitXOffsetPercent] = useState(50);
  const handleInputChange = (event: any) => {
    setPortraitXOffsetPercent(event.target.value);
  };

  const resolutions = [
    { value: 'HD', name: 'HD' },
    { value: '4K', name: '4K' },
  ]
  const [selectedResolution, setSelectedResolution] = useState(resolutions[1]);

  const encodings = [
    { value: '0', name: 'h264' },
    { value: '1', name: 'h265' },
  ];
  const [selectedEncoding, setSelectedEncoding] = useState(encodings[1]);

  const presets = [
    { value: '1', name: 'veryfast' },
    { value: '2', name: 'faster' },
    { value: '3', name: 'fast' },
    { value: '4', name: 'medium' },
    { value: '5', name: 'slow' },
    { value: '6', name: 'veryslow' },
  ];
  const [selectedPreset, setSelectedPreset] = useState(presets[3]);

  const crfs = [
    { value: '1', name: '3' },
    { value: '2', name: '17' },
    { value: '3', name: '21' },
    { value: '4', name: '23' },
    { value: '5', name: '28' },
  ];
  const [selectedCrf, setSelectedCrf] = useState(crfs[4]);

  const framerates = [
    { value: '0', name: '8' },
    { value: '1', name: '30' },
    { value: '2', name: '60' },
  ];
  const [selectedFramerate, setSelectedFramerate] = useState(framerates[0]);

  const blendings = [
    { value: 0, name: 'no' },
    { value: 30, name: '30' },
    { value: 60, name: '60' },
  ];
  const [selectedBlending, setSelectedBlending] = useState(blendings[0]);
  // Main Modal
  return (
    <>
      <DailyPlayerModal ref={dailyPlayer} url={previewMergedDailysURL} />
      <DailyPlayerModal ref={timeLapsePlayer} url={timeLapseUrl} />
      <Transition.Root show={open} as={Fragment}>
        <Dialog as="div" className="relative z-10" onClose={() => { }}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>
          <div className="fixed inset-0 z-10 overflow-y-auto">
            <div className="flex min-h-full items-center justify-center p-4 text-center ">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 translate-y-4 "
                enterTo="opacity-100 translate-y-0 "
                leave="ease-in duration-200"
                leaveFrom="opacity-100 translate-y-0 "
                leaveTo="opacity-0 translate-y-4 "
              >
                <Dialog.Panel className="relative max-w-2xl transform overflow-hidden rounded-lg bg-panterra-800 p-5 text-left shadow-xl transition-all">
                  <div className="flex flex-col items-stretch pt-5">
                    <div className="flex items-center justify-between pb-2">
                      <Dialog.Title className="mx-1 text-2xl text-white">{t('timeLapseGeneratorHeader')}</Dialog.Title>
                      <CustomButton
                        action={() => {
                          clearSelection();
                          setOpen(false);
                        }}
                        tippyContent={t('timeLapseGeneratorBackToStack')}
                        iconProps={{
                          icon: "XMarkIcon",
                        }}
                      />
                    </div>
                    <div className="mx-1 mb-5 text-white ">{`${project.name} - ${camera.name}`}</div>
                    <Tab.Group selectedIndex={selectedIndex} onChange={setSelectedIndex}>
                      <Tab.List>
                        <Tab>
                          <div className={`${selectedIndex === 1 ? selectedTabClasses : tabClasses} mb-3 rounded-md bg-panterra-700 py-1 px-2 text-blue-200`}>
                            <Icon icon="ForwardIcon" />
                            {t('MinuteTimeLapse')}
                          </div>
                        </Tab>
                        <Tab>
                          <div className={`${selectedIndex === 0 ? selectedTabClasses : tabClasses} mb-3 rounded-md bg-panterra-700 py-1 px-2 text-blue-200`}>
                            <Icon icon="ScissorsIcon" />
                            {t('DailyTimelapse')}
                          </div>
                        </Tab>
                      </Tab.List>
                      <Tab.Panels>
                        <Tab.Panel>
                          <div className="mb-3 rounded-md bg-panterra-700 py-1 px-2 text-white text-blue-200">
                            {t('create1MinTimelapseVideoInfoText')}
                          </div>
                          <div className="mb-3 rounded-md bg-panterra-700 py-1 px-2 text-white text-blue-200">
                            <span>
                              {t('timeLapseLongVideo')}
                            </span>
                            <span className="px-2">
                              <Switch checked={enabled} onChange={setEnabled} as={Fragment}>
                                {({ checked }) => (
                                  <button
                                    className={`${checked ? 'bg-panterra-300' : 'bg-panterra-800'
                                      } relative inline-flex h-6 w-11 items-center rounded-full`}
                                  >
                                    <span
                                      className={`${checked ? 'translate-x-6' : 'translate-x-1'
                                        } inline-block h-4 w-4 transform rounded-full bg-white transition`}
                                    />
                                  </button>
                                )}
                              </Switch>
                            </span>
                            <span>
                              {enabled ? t('timeLapseLongLength') : t('timeLapseShortLength')}
                            </span>
                          </div>
                          <div className="mb-3 rounded-md bg-panterra-700 py-1 px-2 text-white text-blue-200">
                            <span>
                              {t('timeLapsePortraitMode')}
                            </span>
                            <span className="px-2">
                              <Switch checked={portraitEnabled} onChange={setPortraitEnabled} as={Fragment}>
                                {({ checked }) => (
                                  <button
                                    className={`${checked ? 'bg-panterra-300' : 'bg-panterra-800'
                                      } relative inline-flex h-6 w-11 items-center rounded-full`}
                                  >
                                    <span
                                      className={`${checked ? 'translate-x-6' : 'translate-x-1'
                                        } inline-block h-4 w-4 transform rounded-full bg-white transition`}
                                    />
                                  </button>
                                )}
                              </Switch>
                            </span>
                            <span className="px-2">
                              <PortraitSelectorModal
                                ref={portraitSelector}
                                position={portraitXOffsetPercent}
                                setPosition={setPortraitXOffsetPercent}
                                projectName={projectName}
                                cameraNumber={cameraNumber}
                                fileName={props.dailys[0].fileName}
                              />
                              {portraitEnabled && (
                                <span>
                                  <CustomButton
                                    action={() => {
                                      portraitSelector.current?.setModalOpen(true);
                                    }}
                                    iconProps={{
                                      icon: "PhotoIcon",
                                    }}
                                    tippyContent={t('tooltip:timeLapseGeneratorSelectPortraitArea')}
                                    buttonPositioning="inline-flex"
                                    buttonBG={"bg-panterra-700"}
                                    buttonPaddingClassName={"flex-row px-2"}
                                    buttonHoverColorClassName={"hover:bg-panterra-500"}
                                  />
                                  <input
                                    disabled={!portraitEnabled}
                                    value={portraitXOffsetPercent}
                                    onChange={handleInputChange}
                                    type="number"
                                    min="0"
                                    max="100"
                                    className="rounded-md bg-panterra-800 text-white"
                                  />
                                  % {t('timeLapsePortraitModeXOffset')}
                                </span>
                              )}
                            </span>
                          </div>
                          {isPtAdmin && (
                            <div className="flex flex-row flex-wrap gap-x2 items-center mb-3 rounded-md bg-panterra-700 py-1 px-2 text-white text-blue-200">
                              <div className="flex flex-row items-center">
                                <CustomListbox values={resolutions} value={selectedResolution} setValue={setSelectedResolution} />
                                <CustomListbox values={encodings} value={selectedEncoding} setValue={setSelectedEncoding} />
                              </div>
                              <div className="flex flex-row items-center">
                              Preset
                              <CustomListbox values={presets} value={selectedPreset} setValue={setSelectedPreset} />
                              CRF
                              <CustomListbox values={crfs} value={selectedCrf} setValue={setSelectedCrf} />
                              </div>
                              <div className="flex flex-row items-center">
                              Framerate
                              <CustomListbox values={framerates} value={selectedFramerate} setValue={setSelectedFramerate} />
                                Blending
                                <CustomListbox values={blendings} value={selectedBlending} setValue={setSelectedBlending} />
                              </div>
                            </div>
                          )}
                          <div className="flex flex-col items-center bg-white">
                            <DayPicker
                              locale={i18n.resolvedLanguage ? lngs[i18n.resolvedLanguage].calendarLocale : en}
                              fromDate={startDate}
                              toDate={endDate}
                              mode="range"
                              numberOfMonths={screenWidth >= 768 ? 2 : 1}
                              selected={range}
                              onSelect={setRange}
                              onDayClick={onDayClick}
                              captionLayout={screenWidth >= 768 ? "dropdown" : "buttons"}
                              modifiersStyles={{ selected: { backgroundColor: "#FF6326" } }}
                            />
                          </div>
                          <div className="mt-3 rounded-md bg-panterra-700 py-1 px-2 text-blue-200">
                            <div className="flex justify-between">
                              <Footer />
                              <span>
                                <CustomButton
                                  action={selectLast7Days}
                                  iconProps={{
                                    icon: "CalendarDaysIcon",
                                    iconClassName: "h-4 w-4",
                                  }}
                                  tippyContent={t('tooltip:timeLapseLast7Days')}
                                  buttonPaddingClassName="p-1 ml-1"
                                  buttonBG="bg-panterra-800"
                                  buttonHoverColorClassName={"hover:bg-panterra-600"}
                                />
                                {t('tooltip:timeLapseLast7Days')}
                              </span>
                              <span>
                                <CustomButton
                                  action={selectLast30Days}
                                  iconProps={{
                                    icon: "CalendarDaysIcon",
                                    iconClassName: "h-4 w-4",
                                  }}
                                  tippyContent={t('tooltip:timeLapseLast30Days')}
                                  buttonPaddingClassName="p-1 ml-1"
                                  buttonBG="bg-panterra-800"
                                  buttonHoverColorClassName={"hover:bg-panterra-600"}
                                />{t('tooltip:timeLapseLast30Days')}
                              </span>
                              <span>
                                <CustomButton
                                  action={selectCurrentYear}
                                  iconProps={{
                                    icon: "CalendarDaysIcon",
                                    iconClassName: "h-4 w-4",
                                  }}
                                  tippyContent={t('tooltip:timeLapseLastCurrentYear')}
                                  buttonPaddingClassName="p-1 ml-1"
                                  buttonBG="bg-panterra-800"
                                  buttonHoverColorClassName={"hover:bg-panterra-600"}
                                />{t('tooltip:timeLapseLastCurrentYear')}
                              </span>
                              <span>
                                <CustomButton
                                  action={selectSinceBeginning}
                                  iconProps={{
                                    icon: "CalendarDaysIcon",
                                    iconClassName: "h-4 w-4",
                                  }}
                                  tippyContent={t('tooltip:timeLapseLastSinceBeginning')}
                                  buttonPaddingClassName="p-1 ml-1"
                                  buttonBG="bg-panterra-800"
                                  buttonHoverColorClassName={"hover:bg-panterra-600"}
                                />{t('tooltip:timeLapseLastSinceBeginning')}
                              </span>
                              <CustomButton
                                action={clearSelection}
                                iconProps={{
                                  icon: "TrashIcon",
                                  iconClassName: "h-4 w-4",
                                }}
                                tippyContent={t('tooltip:timeLapseGeneratorUnselectDateRange')}
                                buttonPaddingClassName="p-1 ml-1"
                                buttonBG="bg-panterra-800"
                                buttonHoverColorClassName={"hover:bg-panterra-600"}
                              />
                            </div>
                          </div>
                          <div className="flex flex-row gap-x-2 py-3 text-sm font-medium text-black">
                            <CustomButton
                              action={requestTimeLapseVideo}
                              iconProps={{
                                icon: buildInProgress ? "HourGlassSVG" : "GearSVG",
                              }}
                              tippyContent={t('tooltip:timeLapseGeneratorStartCut')}
                              buttonPositioning="w-full"
                              buttonBG={"bg-panterra-700"}
                              buttonPaddingClassName={"w-full flex flex-row justify-center py-2"}
                              buttonDisabled={buildInProgress}
                              buttonHoverColorClassName={"hover:bg-panterra-500"}
                            />
                            <CustomButton
                              action={() => {
                                timeLapsePlayer.current?.setModalOpen(true);
                              }}
                              iconProps={{
                                icon: "PlayIcon",
                              }}
                              tippyContent={t('tooltip:timeLapseGeneratorStartVideoPreview')}
                              buttonPositioning="w-full"
                              buttonBG={"bg-panterra-700"}
                              buttonPaddingClassName={"w-full flex flex-row justify-center py-2"}
                              buttonDisabled={false}
                              buttonHoverColorClassName={"hover:bg-panterra-500"}
                            />
                            <CustomButton
                              href={timeLapseUrl}
                              iconProps={{
                                icon: "ArrowDownTrayIcon",
                              }}
                              tippyContent={t('tooltip:downloadVideo')}
                              buttonBG={"bg-panterra-700"}
                              buttonPaddingClassName={"w-full flex flex-row justify-center py-2"}
                              buttonDisabled={false}
                              buttonHoverColorClassName={"hover:bg-panterra-500"}
                            />
                          </div>
                        </Tab.Panel>
                        <Tab.Panel>
                          <div className="mb-3 rounded-md bg-panterra-700 py-1 px-2 text-white text-blue-200">
                            {t('mergeDailyTimelapseVideosInfoText')}
                          </div>
                          <div className="mb-3 rounded-md bg-panterra-700 py-1 px-2 text-white text-blue-200">
                            {t('timeLapseGeneratorMax31Days')}
                          </div>
                          <div className="flex flex-col items-center bg-white">
                            <DayPicker
                              locale={i18n.resolvedLanguage ? lngs[i18n.resolvedLanguage].calendarLocale : en}
                              fromDate={startDate}
                              toDate={endDate}
                              mode="range"
                              numberOfMonths={screenWidth >= 768 ? 2 : 1}
                              min={2}
                              max={31}
                              selected={range}
                              onSelect={setRange}
                              onDayClick={onDayClick}
                              captionLayout={screenWidth >= 768 ? "dropdown" : "buttons"}
                              modifiersStyles={{ selected: { backgroundColor: "#FF6326" } }}
                            />
                          </div>
                          <div className="mt-3 flex justify-between rounded-md bg-panterra-700 py-1 px-2 text-blue-200">
                            <Footer />
                            <CustomButton
                              action={clearSelection}
                              iconProps={{
                                icon: "TrashIcon",
                                iconClassName: "h-4 w-4",
                              }}
                              tippyContent={t('tooltip:timeLapseGeneratorUnselectDateRange')}
                              buttonPaddingClassName="p-1 ml-1"
                              buttonBG="bg-panterra-800"
                              buttonHoverColorClassName={"hover:bg-panterra-600"}
                            />
                          </div>
                          <div className="flex flex-row gap-x-2 py-3 text-sm font-medium text-black">
                            <CustomButton
                              action={() => requestMergedDailysDownloadKey()}
                              iconProps={{
                                icon: buildInProgress ? "HourGlassSVG" : "GearSVG",
                              }}
                              tippyContent={validRange ? t('tooltip:timeLapseGeneratorStartCut') : t('tooltip:timeLapseGeneratorSelectValidRange')}
                              buttonPositioning="w-full"
                              buttonBG={"bg-panterra-700"}
                              buttonPaddingClassName={"w-full flex flex-row justify-center py-2"}
                              buttonDisabled={buildInProgress || !validRange}
                              buttonHoverColorClassName={"hover:bg-panterra-500"}
                            />
                            <CustomButton
                              action={() => {
                                dailyPlayer.current?.setModalOpen(true);
                              }}
                              iconProps={{
                                icon: "PlayIcon",
                              }}
                              tippyContent={
                                mergedDailysDownloadKey
                                  ? t('tooltip:timeLapseGeneratorStartVideoPreview')
                                  : t('tooltip:timeLapseGeneratorSelectValidRangeAndProcess')
                              }
                              buttonPositioning="w-full"
                              buttonBG={"bg-panterra-700"}
                              buttonPaddingClassName={"w-full flex flex-row justify-center py-2"}
                              buttonDisabled={mergedDailysDownloadKey ? false : true}
                              buttonHoverColorClassName={"hover:bg-panterra-500"}
                            />
                            <CustomButton
                              href={downloadMergedDailysURL}
                              iconProps={{
                                icon: "ArrowDownTrayIcon",
                              }}
                              tippyContent={
                                mergedDailysDownloadKey
                                  ? t('tooltip:downloadVideo')
                                  : t('tooltip:timeLapseGeneratorSelectValidRangeAndProcess')
                              }
                              buttonBG={"bg-panterra-700"}
                              buttonPaddingClassName={"w-full flex flex-row justify-center py-2"}
                              buttonDisabled={mergedDailysDownloadKey ? false : true}
                              buttonHoverColorClassName={"hover:bg-panterra-500"}
                            />
                          </div>
                        </Tab.Panel>
                      </Tab.Panels>
                    </Tab.Group>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition.Root>
      <Toaster
        toastOptions={{
          className: "bg-panterra-500 text-white",
        }}
      />
    </>
  );
});
