import { CustomWidget, Project} from "../../../types/types"
import { ImageFooter } from "../../shared/ImageFooter/ImageFooter"
import DangerouslySetHtmlContent from "../../shared/DangerouslySetHtmlContent/DangerouslySetHtmlContent"
import CSS from 'csstype'
import { useTranslation } from "react-i18next"

export type CustomWidgetProps = {
  customWidget: CustomWidget
  project: Project
}

export const CustomWidgetTile = (props: CustomWidgetProps) => {
  const {i18n} = useTranslation();
  const customWidgetStyle : CSS.Properties = {
    aspectRatio: "870/580",
    width: "100%",
    overflow: "hidden",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  }
  if (props.customWidget.backgroundImage) {
    customWidgetStyle.backgroundImage = `url(${process.env.REACT_APP_API_ENTRYPOINT}assets/image_widgets/${encodeURI(props.customWidget.backgroundImage)})`;
    customWidgetStyle.backgroundSize = "cover";
    customWidgetStyle.backgroundPosition= "center";
  }
  if (props.customWidget.backgroundColor && !props.customWidget.backgroundImage) {
    customWidgetStyle.background = props.customWidget.backgroundColor;
  }

  return (
    <div>
      {props.customWidget.autoIFrame ?
      <div
        style={{
          aspectRatio: "870/580",
          width: "100%"
        }}>
        <iframe
          title={props.customWidget.name}
          frameBorder={0}
          allowFullScreen={true}
          allow={"fullscreen"}
          className="w-full h-full"
          src={props.customWidget.code.replace("onlinePortalBrowserLanguage", i18n.resolvedLanguage ? i18n.resolvedLanguage : "en")}
        />
      </div> :
      <DangerouslySetHtmlContent
        style = {customWidgetStyle}
        html={props.customWidget.code.replace("onlinePortalBrowserLanguage", i18n.resolvedLanguage ? i18n.resolvedLanguage : "en")}/>

      }
        <ImageFooter>
          <span
            className="whitespace-nowrap overflow-hidden overflow-ellipsis">
            {props.project.name} - {props.customWidget.name}
          </span>
        </ImageFooter>
    </div>
  )
}
