/* eslint-disable react-hooks/exhaustive-deps */
import {useNavigate, useParams} from "react-router";
import {TileSourceOptions} from "openseadragon";
import {useContext, useEffect, useRef, useState} from "react";
import apiFetch from "../../../services/dataAccess";
import {ProjectsContext} from "../../../context/ProjectsProvider";
import {ConciseRecording, ProjectAndCamParams} from "../../../types/types";
import {ImageUrlFormatter} from "../../../services/urlFormatter";
import {CenterPoint, ITileViewer, TileViewer} from "./TileViewer";
import {Pageloader} from "../../shared/Pageloader/Pageloader";
import {DateTimeFormatter} from "../../../services/stringFormatter";
import {CompareImagesContext} from "../../../context/CompareImagesProvider";
import {CustomButton} from "../../shared/CustomButton/CustomButton";
import screenfull from "screenfull";
import {ProjectBrandingPTLogo} from "../../shared/ProjectBrandingPTLogo/ProjectBrandingPTLogo";
import {ProjectBrandingClientLogo} from "../../shared/ProjectBrandingClientLogo/ProjectBrandingClientLogo";
import {ControlBar} from "./ControlBar";
import Tippy from "@tippyjs/react";
import {OSDLegend} from "./OSDLegend";
import {QuestionMarkCircleIcon} from "@heroicons/react/24/solid";
import { BlankPageWithBackButton } from "../../shared/BlankPage/BlankPageWithBackButton";
import { useTranslation } from "react-i18next";

export const DualZoom = () => {
  const navigate = useNavigate();
  const {projectName: currentProject, cameraNumber} = useParams<ProjectAndCamParams>() as ProjectAndCamParams;
  const {state: projects} = useContext(ProjectsContext);
  const {t} = useTranslation();

  const [recordings, setRecordings] = useState<ConciseRecording[]>([]);
  const [tileSources, setTileSources] = useState<Partial<TileSourceOptions>[]>([]);
  const {compareImages} = useContext(CompareImagesContext);
  const [isFullScreen, setIsFullScreen] = useState<boolean>(false);

  const project = projects[currentProject];
  const camera = project.cameras[cameraNumber];
  const tileViewerIds = [0, 1];
  const tileViewers = tileViewerIds.map(() => {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const tileViewer = useRef<ITileViewer>(null);
    return tileViewer;
  });

  const wrapper = useRef<HTMLDivElement>(null);

  const generateTileSource = (datetime: string): TileSourceOptions & {type: string; tilesUrl: string} => {
    return {
      type: "zoomifytileservice",
      width: camera.pixelWidth,
      height: camera.pixelHeight,
      tilesUrl: ImageUrlFormatter(currentProject, cameraNumber, datetime, "zoom"),
    };
  };

  useEffect(() => {
    if (screenfull.isEnabled) {
      screenfull.on("change", () => setIsFullScreen(screenfull.isFullscreen));
    }
    return () => {
      if (screenfull.isEnabled) {
        screenfull.off("change", () => setIsFullScreen(screenfull.isFullscreen));
      }
    };
  });

  useEffect(() => {
    apiFetch(`cameras/${camera.id}/recordings/concise`)
      .then((response) => response.json())
      .then((data) => {
        setRecordings(data);
      });
  }, []);

  useEffect(() => {
    if (!recordings.length) return;
    const _tileSources: TileSourceOptions[] = compareImages.map((index) =>
      generateTileSource(recordings[index].timestamp),
    );
    setTileSources(_tileSources);
  }, [recordings]);

  const handleNavigate = (zoomLevel: number, coordinates: CenterPoint, id: number) => {
    const otherId = id === 0 ? 1 : 0;
    tileViewers[otherId].current?.navigateTo(zoomLevel, coordinates);
  };

  const toggleFullscreen = () => {
    if (screenfull.isEnabled && wrapper.current) {
      screenfull.toggle(wrapper.current);
    }
  };

  // Do not render if no data is available. Show message if client used browser navigation to get here while clearing compareImages context
  if (tileSources.length === 0) {
    return (
      <BlankPageWithBackButton>
        {compareImages.length !== 2 ? (
              <div>
                {t('imageSelectionNotValid')}
              </div>
            ) : (
              <Pageloader />
            )}
      </BlankPageWithBackButton>
    )
  }

  return (
    <div className="container mx-auto flex h-full flex-col pt-8 pb-8">
      {/* Two Tileviewers */}
      <div ref={wrapper} className={"relative flex flex-grow flex-row items-stretch bg-panterra-900"}>
        {tileViewerIds.map((id) => (
          <TileViewer
            key={id}
            showNavigator={id === 0 ? false : true}
            ref={tileViewers[id]}
            id={id}
            tileSource={tileSources[id]}
            handleNav={handleNavigate}
          ></TileViewer>
        ))}

        {/* Controls and Overlays */}
        {project.showPTLogoInTF && <ProjectBrandingPTLogo className={"!bottom-12 !left-5"}></ProjectBrandingPTLogo>}
        {project.showClientLogoInTF && (
          <ProjectBrandingClientLogo
            projectLogo={project.projectLogo}
            className={"absolute top-2 right-24 z-20 h-8"}
          ></ProjectBrandingClientLogo>
        )}
        <ControlBar
          isFullScreen={isFullScreen}
          goHome={() => tileViewers.forEach((tileViewer) => tileViewer.current?.goHome())}
          zoomIn={() => tileViewers.forEach((tileViewer) => tileViewer.current?.zoomIn())}
          zoomOut={() => tileViewers.forEach((tileViewer) => tileViewer.current?.zoomOut())}
          toggleFullScreen={toggleFullscreen}
        ></ControlBar>
        <Tippy content={<OSDLegend />} placement={"bottom-end"}>
          <div className="absolute top-0 right-11 m-1 rounded-md bg-panterra-800 p-1 hover:bg-panterra-700">
            <QuestionMarkCircleIcon className="h-7 w-7" />
          </div>
        </Tippy>
        <CustomButton
          action={() => navigate(-1)}
          buttonPositioning="absolute top-0 right-0"
          buttonPaddingClassName="p-1 m-1"
          buttonBG="bg-panterra-800"
          iconProps={{
            icon: "XMarkIcon",
          }}
          tippyContent={t('tooltip:navigateBack')}
        ></CustomButton>
        <div className="absolute bottom-0 left-0 bg-panterra-800 px-1 text-lg">
          {compareImages.map((index) => DateTimeFormatter(recordings[index].timestamp)).join(" -- ")}
        </div>
      </div>
    </div>
  );
};
