import {Route, Routes} from "react-router-dom";
import {Home} from "./layout/Home/Home";
import {InitialProjectDataFetcher} from "../context/InitialProjectDataFetcher";
import {Navbar} from "./layout/Navbar";
import {ImageStack} from "./projects/Stack/ImageStack";
import {SingleZoom} from "./projects/Zoom/SingleZoom";
import {DualZoom} from "./projects/Zoom/DualZoom";
import {DailyPlayer} from "./projects/Video/DailyPlayer";
import {DualFade} from "./projects/Zoom/DualFade";
import {DailyStack} from "./projects/Stack/DailyStack";
import {ProtectedRoute} from "./shared/ProtectedRoute/ProtectedRoute";
import { UnTiledSingleZoom } from "./projects/Zoom/UnTiledSingleZoom";
import { ProjectOverview } from "./projects/ProjectOverview/ProjectOverview";
import { Suspense } from 'react';

export const App = () => {
  return (
    <Suspense fallback="loading">
      <div className={"flex h-full min-h-full flex-col overflow-auto bg-panterra-900 text-panterra-50"}>
        <InitialProjectDataFetcher>
          <Navbar />
          <Routes>
            <Route path="/" element={<Home />} />
            <Route
              path="projects/:projectName"
              element={
                <ProtectedRoute>
                  <ProjectOverview />
                </ProtectedRoute>
              }
            />
            <Route
              path="projects/:projectName/image_widgets/:imageWidgetId"
              element={
                <ProtectedRoute>
                  <UnTiledSingleZoom />
                </ProtectedRoute>
              }
            />
            <Route
              path="projects/:projectName/:cameraNumber"
              element={
                <ProtectedRoute>
                  <ImageStack />
                </ProtectedRoute>
              }
            />
            <Route
              path="projects/:projectName/:cameraNumber/dailys"
              element={
                <ProtectedRoute>
                  <DailyStack />
                </ProtectedRoute>
              }
            />
            <Route
              path="projects/:projectName/:cameraNumber/dailys/play"
              element={
                <ProtectedRoute>
                  <DailyPlayer />
                </ProtectedRoute>
              }
            />
            <Route
              path="projects/:projectName/:cameraNumber/zoom"
              element={
                <ProtectedRoute>
                  <SingleZoom />
                </ProtectedRoute>
              }
            />
            <Route
              path="projects/:projectName/:cameraNumber/dualzoom"
              element={
                <ProtectedRoute>
                  <DualZoom />
                </ProtectedRoute>
              }
            />
            <Route
              path="projects/:projectName/:cameraNumber/dualfade"
              element={
                <ProtectedRoute>
                  <DualFade />
                </ProtectedRoute>
              }
            />
          </Routes>
        </InitialProjectDataFetcher>
      </div>
    </Suspense>
  );
};
