import { useContext } from "react";
import { useParams } from "react-router-dom";
import { ProjectsContext } from "../../../context/ProjectsProvider";
import { ProjectTiles } from "../../layout/ProjectTiles";

type ProjectLatestImagesParams = {
  projectName: string;
};

export const ProjectOverview = () => {
  const {projectName: currentProject} = useParams<ProjectLatestImagesParams>() as ProjectLatestImagesParams;
  const {state} = useContext(ProjectsContext);
  const project = state[currentProject];

  return (
    <div className={"container mx-auto pt-8 pb-8"}>
      <ProjectTiles project={project}/>
    </div>
  );
};
