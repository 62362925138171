/* eslint-disable react-hooks/exhaustive-deps */
import {PropsWithChildren, useContext, useEffect, useState} from "react";
import {ProjectsContext} from "./ProjectsProvider";
import {getProjectData} from "../services/api/projectApi";
import {useKeycloak} from "@react-keycloak/web";
import {UserConfigurationContext} from "./UserConfigurationProvider";
import {Pageloader} from "../components/shared/Pageloader/Pageloader";
import { useTranslation } from "react-i18next";

export const InitialProjectDataFetcher = (props: PropsWithChildren<any>) => {
  const {state, dispatch} = useContext(ProjectsContext);
  const {hasProjects, setHasProjects} = useContext(UserConfigurationContext);
  const [apiReturned, setApiReturned] = useState(false);
  const {keycloak} = useKeycloak();
  const {t} = useTranslation();

  // get initial project data from API
  useEffect(() => {
    const getData = async () => {
      const projects = await getProjectData();
      dispatch({type: "SET_DATA", payload: projects});
      setApiReturned(true);
    };
    getData();
  }, [dispatch, keycloak]);

  // Quickfix variable hasProjects differentiates new Users from Users with pending API request. Time ran out to properly include the hasProjects state in the state context
  // This little workaround makes sure setState Batch execution does not set hasProjects before projects are actually set.
  useEffect(() => {
    let hasProjects = null;
    if (apiReturned && Object.keys(state).length > 0) {
      hasProjects = true;
    } else if (apiReturned && Object.keys(state).length === 0) {
      hasProjects = false;
    }
    setHasProjects(hasProjects);
  }, [state, apiReturned]);

  // return Loader if API did not return yet.
  if (hasProjects === null) {
    return (
      <div className={"flex h-full min-h-full flex-col overflow-auto bg-panterra-900 text-panterra-50"}>
        <div className={"mx-auto min-h-full bg-panterra-900"}>
          <div className={"py-10 text-xl"}>{t('dataLoading')}</div>
          <Pageloader />
        </div>
      </div>
    );
  }

  return props.children;
};
