import {Link} from "react-router-dom";
import {PropsWithChildren, ReactNode, SyntheticEvent, useState} from "react";
import {Transition} from "@headlessui/react";
import {CustomButton} from "../CustomButton/CustomButton";

type Props = {
  label: string;
  link: string;
  icon: ReactNode;
  externalLink?: boolean;
};

export const MenuItem = (props: PropsWithChildren<Props>) => {
  const [showSubmenu, setShowSubmenu] = useState(false);

  const toggleSubMenu = (e: SyntheticEvent) => {
    e.preventDefault();
    setShowSubmenu((current) => !current);
  };

  const toggleSubMenuButton = props.children ? (
    !showSubmenu ? (
      <CustomButton
        action={(e) => toggleSubMenu(e)}
        buttonPaddingClassName={"p-0"}
        iconProps={{icon: "ChevronDownIcon"}}
      />
    ) : (
      <CustomButton
        action={(e) => toggleSubMenu(e)}
        buttonPaddingClassName={"p-0"}
        iconProps={{icon: "ChevronUpIcon"}}
      />
    )
  ) : (
    ""
  );

  return (
    <>
      {" "}
      {props.externalLink ? (
        <a
          href={props.link}
          target="_blank"
          rel="noopener noreferrer"
          className={
            "flex items-center rounded-lg p-3 text-lg text-panterra-100 hover:bg-panterra-600 hover:text-panterra-50"
          }
        >
          {props.icon}
          <span className={"ml-5 flex-grow"}>{props.label}</span>
          {toggleSubMenuButton}
        </a>
      ) : (
        <Link
          to={props.link}
          className={
            "flex items-center rounded-lg p-3 text-lg text-panterra-100 hover:bg-panterra-600 hover:text-panterra-50"
          }
        >
          {props.icon}
          <span className={"ml-5 flex-grow"}>{props.label}</span>
          {toggleSubMenuButton}
        </Link>
      )}
      <Transition
        show={showSubmenu}
        enter="transition-opacity duration-500"
        enterFrom="opacity-0"
        enterTo="opacity-100"
        leave="transition-opacity duration-300"
        leaveFrom="opacity-100"
        leaveTo="opacity-0"
      >
        {props.children}
      </Transition>
    </>
  );
};
